<template>
  <PageWithPanel
    :title="pageTitle"
    :actions="pageActions"
    :no-padding="$route.name == RoutesName.Search"
  >
    <template #titlePrepend>
      <q-icon
        v-if="isHistory"
        name="history"
        size="sm"
        color="primary"
        class="q-pa-sm"
      />
      <q-img
        v-if="isPublicationIconVisible"
        :alt="pagePublication"
        style="width: 25px"
        :src="`/img/${pagePublication}.png`"
      />
    </template>
    <template
      v-if="articleContent && $route.name == RoutesName.EditArticle"
      #menuTop
    >
      <div
        class="row wrap justify-start items-center content-start q-gutter-sm"
      >
        <q-icon
          v-if="showWarningIcon"
          color="orange-5"
          name="warning"
          size="sm"
        >
          <q-tooltip
            content-class="bg-primary text-white"
            content-style="white-space: pre-wrap;"
          >
            {{ warningInterestAssociationTooltipMessage }}
          </q-tooltip>
        </q-icon>
        <q-icon
          v-if="articleContent.online"
          color="primary"
          name="check_circle"
          size="sm"
        >
          <q-tooltip content-class="bg-primary text-white">
            {{ $t("article.helpStates.online") }}
          </q-tooltip>
        </q-icon>
        <div>
          <InputArticleContentStatus
            :article="articleShowing"
            :article-content="articleContent as ArticleContentDto"
            :disable="changeStateDisabled"
            @update:state="handleStateChanged"
          />
          <q-tooltip
            v-if="statusTooltipMessage"
            content-class="bg-primary text-white"
          >
            {{ statusTooltipMessage }}
          </q-tooltip>
        </div>
      </div>
    </template>
    <template #panelRight>
      <div v-if="articleShowing.id != 0">
        <PanelEditArticle />
      </div>
      <div v-else class="q-pa-md">
        <FormSimpleArticle :key="formSimpleArticle" @submit="onCreate" />
      </div>
      <q-inner-loading
        :showing="loading"
        :label="$t('messages.waiting')"
        label-class="text-teal"
        label-style="font-size: 1.1em"
      />
    </template>
    <template #default>
      <q-page-sticky
        v-if="isDraft && $q.screen.lt.sm"
        position="bottom-left"
        :offset="[18, 18]"
      >
        <q-fab
          v-if="isHistory"
          icon="warning"
          direction="up"
          vertical-actions-align="left"
          color="negative"
        >
          <div
            class="q-pa-sm rounded-borders bg-negative text-white text-subtitle2"
            style="width: 70vw"
          >
            {{ $t("article.messages.draftNoEditable") }}
          </div>
        </q-fab>
        <q-fab
          v-else
          icon="warning"
          direction="up"
          vertical-actions-align="left"
          color="warning"
        >
          <div
            class="q-pa-sm rounded-borders bg-warning text-subtitle2"
            style="width: 70vw"
          >
            {{ $t("article.messages.draftEditable") }}
          </div>
        </q-fab>
      </q-page-sticky>
    </template>
  </PageWithPanel>
</template>

<script setup lang="ts">
import type { PageAction } from "@/types/page";
import {
  type ArticleDto,
  type ArticleContentDto,
  type WorkflowFireResult,
  RightReference,
  ContributionType,
  SettingsType,
  WorkflowState,
} from "@/types/api";
import { computed, ref, inject } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import { EventBus } from "quasar";
import { storeToRefs } from "pinia";
import { useArticleActions } from "@/composables/useArticleActions";
import { RoutesName } from "@/router/routesName";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import FormSimpleArticle from "@/components/Article/FormSimpleArticle.vue";
import PanelEditArticle from "@/components/Article/PanelEditArticle.vue";
import InputArticleContentStatus from "@/components/Article/InputArticleContentStatus.vue";
import {
  modificationType,
  getModificationTypeFromLanguage,
  MINIMUM_ASSOCIATED_ARTICLES,
} from "@/components/Article/constant";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { i18n } from "@/i18n";
import { useConfigStore } from "@/stores/config";
import { authService } from "@/services/AuthService";

const configStore = useConfigStore();
const { config } = storeToRefs(configStore);

const { loading, actions } = useArticleActions();
const currentArticleStore = useCurrentArticleStore();
const {
  currentArticle: articleShowing,
  isHistory,
  isDraft,
  modifExist,
  lockType,
  lang,
  locks,
  computingTextWithSuggestions,
} = storeToRefs(currentArticleStore);
const route = useRoute();
const bus = inject<EventBus>("bus");

let formSimpleArticle = ref<number>(0);
const articleContent = computed(() => {
  const content = articleShowing.value.contents.find(
    (c) => c.language == lang.value
  );
  return content ?? articleShowing.value.contents[0];
});
const pageTitle = computed(() => {
  if (route.name === RoutesName.EditArticle) {
    let prefix = "";

    if (articleShowing.value.deleted)
      prefix += "[" + i18n.t("article.tags.deleted") + "] ";
    if (isDraft.value) prefix += "[" + i18n.t("article.tags.draft") + "] ";
    if (isHistory.value)
      prefix += "[" + i18n.t("article.tags.unchangeable") + "] ";

    return `${prefix}${articleContent.value?.title}`;
  } else if (listView.value === "daily") return i18n.t("liens.EditionsLong");
  else if (listView.value === "fridge") return i18n.t("liens.FridgeLong");
  else if (listView.value === "period" && format.value === "Feuilleton")
    return i18n.t("liens.FeuilletonsLong");
  else return undefined;
});

const listView = computed(() => {
  const { listView: l } = route.query;
  return (l as string) ?? "";
});
const format = computed(() => {
  const { format: l } = route.query;
  return (l as string) ?? "";
});
const pagePublication = computed(() => {
  return route.name === RoutesName.EditArticle
    ? articleShowing.value.publication
    : undefined;
});
const isPublicationIconVisible = computed<boolean>(() => {
  if (!pagePublication.value) return false;
  return pagePublication.value != config.value.publication;
});
const changeStateDisabled = computed(() => {
  return (
    modifExist.value !== modificationType.None || lockInCurrentLanguage.value
  );
});
const canDelete = computed(() => {
  if (
    route.name !== RoutesName.EditArticle ||
    articleShowing.value.deleted ||
    isHistory.value ||
    articleShowing.value.contents.some((c) => c.idBackOffice !== undefined)
  ) {
    return false;
  }

  const isAuthor = articleShowing.value.contributions.some(
    (c) =>
      c.type === ContributionType.Author &&
      c.idContributor === authService.getUserId()
  );

  return (
    isAuthor ||
    authService.hasRight(
      RightReference.PUBLICATION_ARTICLE_ACTION_DELETE,
      articleShowing.value.publication
    )
  );
});
const lockInCurrentLanguage = computed(() => {
  const modifTypeForCurrentLang = getModificationTypeFromLanguage(lang.value);
  return locks.value.some(
    (l) =>
      (l.modificationType & modifTypeForCurrentLang) > 0 &&
      l.userId !== authService.getUserId()
  );
});
const loadingSavingState = computed(() => {
  return currentArticleStore.loading || currentArticleStore.saving;
});
const pageActions = computed<PageAction[]>(() =>
  [
    {
      label: i18n.t("article.labels.btnSave"),
      shortLabel: i18n.t("article.labels.shortBtnEdit"),
      icon: "save",
      disable:
        modifExist.value == modificationType.None ||
        computingTextWithSuggestions.value,
      handler: () => bus?.emit("FormArticle.submit"),
      loading: loadingSavingState.value,
      visible: route.name === RoutesName.EditArticle && !isHistory.value,
    },
    {
      label: i18n.t("article.labels.btnNew"),
      shortLabel: i18n.t("article.labels.shortBtnNew"),
      icon: "add",
      disable: false,
      handler: actions.createArticle,
      visible:
        route.name !== RoutesName.CreateArticle &&
        route.name !== RoutesName.EditArticle,
    },
    {
      label: i18n.t("article.labels.btnDelete"),
      shortLabel: i18n.t("article.labels.shortBtnDelete"),
      icon: "delete",
      color: "black",
      handler: onDelete,
      disable: false,
      visible: canDelete.value,
    },
  ].filter((act) => act.visible)
);
const statusTooltipMessage = computed(() => {
  if (isHistory.value) return i18n.t("article.helpStates.history");

  if (modifExist.value != modificationType.None)
    return i18n.t("article.helpStates.notSaved");

  if (lockInCurrentLanguage.value) return i18n.t("article.helpStates.locked");

  return undefined;
});

const showWarningIcon = computed(() => {
  if (
    articleContent.value?.state != WorkflowState.ImportationEnErreur &&
    articleContent.value?.state != WorkflowState.Importe &&
    articleContent.value?.state != WorkflowState.CorrectionSr &&
    !authService.isEditor(articleShowing.value.publication)
  )
    return false;

  return (
    !articleShowing.value.settings.some(
      (s) => s.idType == SettingsType.Interest
    ) ||
    articleShowing.value.associatedArticles.length < MINIMUM_ASSOCIATED_ARTICLES
  );
});

const warningInterestAssociationTooltipMessage = computed(() => {
  if (
    articleContent.value?.state != WorkflowState.ImportationEnErreur &&
    articleContent.value?.state != WorkflowState.Importe &&
    articleContent.value?.state != WorkflowState.CorrectionSr &&
    !authService.isEditor(articleShowing.value.publication)
  )
    return "";

  const message: string[] = [];

  if (
    !articleShowing.value.settings.some(
      (s) => s.idType == SettingsType.Interest
    )
  ) {
    message.push(i18n.t("article.helpStates.noInterest"));
  }

  if (
    articleShowing.value.associatedArticles.length < MINIMUM_ASSOCIATED_ARTICLES
  ) {
    message.push(i18n.t("article.helpStates.notEnoughAssociatedArticles"));
  }

  return message.join(" ");
});

onBeforeRouteUpdate((to) => {
  if (to.name !== RoutesName.EditArticle) currentArticleStore.initArticle();
});

/**
 * Fonction appelé pour supprimer un article
 */
function onDelete() {
  actions.onDeleteArticle(parseInt(route.params.id as string, 10));
}

/**
 *
 */
function onCreate(article: ArticleDto) {
  actions.onCreateArticle(article, () => {
    formSimpleArticle.value += 1;
  });
}

/**
 * Fonction appelé au moment du changement d'état dans le workflow
 */
function handleStateChanged(stateUpdated: WorkflowFireResult) {
  if (!articleContent.value) return;
  currentArticleStore.stopWatchers();
  articleContent.value.state = stateUpdated.state;
  articleContent.value.xmin = stateUpdated.xmin;
  lockType.value = 0;
  currentArticleStore.startWatchers();
}
</script>
