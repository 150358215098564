<template>
  <q-card bordered>
    <q-card-section class="q-pb-none">
      <div class="row wrap justify-start items-start content-start q-gutter-sm">
        <CustomResponsiveMenu
          color="secondary"
          :label="$t('article.labels.btnSettings')"
          :icon="'settings'"
          :items="[
            'item1:notresponsive',
            'item2:responsive',
            'item3:responsive',
          ]"
        >
          <template #item1>
            <InputDate
              v-model="localPublishedFrom"
              type="date"
              :add-day="true"
              dense
              :label="$t('article.fields.publishedOn')"
            >
              <template #before>
                <q-btn
                  outline
                  round
                  icon="chevron_left"
                  @click="handlePreviousWeek"
                />
              </template>
              <template #after>
                <q-btn
                  outline
                  round
                  icon="chevron_right"
                  @click="handleNextWeek"
                />
              </template>
            </InputDate>
          </template>
          <template #item2>
            <q-btn-dropdown
              color="grey-5"
              :label="$t(`languages.${languageFilter}`)"
            >
              <q-list>
                <q-item
                  v-for="(lang, idx) in languages"
                  :key="'language' + idx"
                  v-close-popup
                  :label="$t(`languages.${lang}`)"
                  dense
                  clickable
                  @click="languageFilter = lang"
                >
                  <q-item-section>{{ $t(`languages.${lang}`) }}</q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </template>
          <template #item3>
            <AutoRefresh
              :auto-refresh="true"
              :auto-refresh-time-in-seconds="60"
              :refresh-function="() => searchArticles()"
              :refresh-condition-checker="() => true"
              :auto-refresh-toggle="true"
            />
          </template>
        </CustomResponsiveMenu>
      </div>
    </q-card-section>
    <q-card-section>
      <q-table
        class="table-sticky-header"
        flat
        bordered
        hide-pagination
        :pagination="{ rowsPerPage: 100 }"
        :columns="columns"
        :rows="rows"
      >
        <template #header-cell="{ col }">
          <q-th class="table-header">
            <div>
              {{ col.label }}
              <template v-if="col.name !== 'section'">
                {{ formatDateForHeaderCell(col.name) }}
                <q-btn
                  icon="launch"
                  size="sm"
                  flat
                  :to="{
                    name: RoutesName.Articles,
                    query: { startDate: daysInWeek[col.name] },
                  }"
                >
                  <q-tooltip>{{
                    $t(`article.fields.helpOpenEdition`)
                  }}</q-tooltip>
                </q-btn>
              </template>
            </div>
            <div v-if="col.name !== 'section'">
              {{
                $t(`article.fields.countArticles`, [
                  getArticlesCountByDate(col.name),
                ])
              }}
            </div>
            <div v-if="col.name !== 'section'">
              <span class="q-mr-md">
                {{
                  $t(`article.fields.countWords`, [
                    getWordsCountByDate(col.name).toLocaleString(),
                  ])
                }}
              </span>
              <span>
                {{
                  $t(`article.fields.countChars`, [
                    getCharactersCountByDate(col.name).toLocaleString(),
                  ])
                }}
              </span>
            </div>
          </q-th>
        </template>
        <template #body-cell="{ row, col }">
          <q-td
            style="max-width: 250px; white-space: normal; vertical-align: top"
            :style="
              getSectionCSSColors(row.section.id, true) +
              (col.name == 'section' ? 'border-right-width: 1px' : null)
            "
          >
            <div v-if="col.name !== 'section'">
              <q-card
                v-for="art of row[col.name]"
                :key="art.id"
                :class="{
                  'q-mb-sm': true,
                  'cursor-pointer': true,
                  'card-selected':
                    art.id === currentArticleStore.currentArticle.id,
                  'bg-white': row.section.id !== 0,
                }"
                :bordered="false"
                :style="
                  row.section.id === 0 &&
                  getSectionCSSColors(getMainSection(art.sections)!.id, true)
                "
                @click="handleShowSettings(art.id)"
              >
                <q-menu touch-position context-menu class="menu-above-tooltip">
                  <q-list dense style="min-width: 100px">
                    <q-item
                      v-if="getActions(art).canTranslate"
                      v-close-popup
                      clickable
                      @click="handleCreateTranslation(art.id)"
                    >
                      <q-item-section>{{
                        $t("planning.actions.createTranslation")
                      }}</q-item-section>
                    </q-item>
                    <q-item v-if="getActions(art).changeDate" clickable>
                      <q-item-section>{{
                        $t("planning.actions.changeDate")
                      }}</q-item-section>
                      <q-item-section side>
                        <q-icon name="keyboard_arrow_right" />
                      </q-item-section>
                      <q-menu anchor="top end" self="top start">
                        <q-list>
                          <q-item
                            v-for="n in getChangePublicationDateOptions(art)"
                            :key="n.label"
                            v-close-popup
                            dense
                            clickable
                            @click="handleChangeDate(art, n.date)"
                          >
                            <q-item-section
                              style="text-transform: capitalize"
                              >{{ n.label }}</q-item-section
                            >
                          </q-item>
                          <q-item
                            v-close-popup
                            clickable
                            dense
                            @click="handleShowDialogChangePublishedOn(art)"
                          >
                            <q-item-section>{{
                              $t("planning.actions.choseDate")
                            }}</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-item>
                    <q-item
                      v-if="getActions(art).noAction"
                      v-close-popup
                      clickable
                    >
                      <q-item-section>{{
                        $t("planning.actions.noAction")
                      }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
                <q-card-section class="q-pa-sm">
                  <q-badge
                    v-if="
                      getTranslationLanguage(art) ||
                      needToBeTranslated(art, languageFilter)
                    "
                    icon="translate"
                    rounded
                    floating
                    :class="{
                      'badge-translate-margin': getCSSColorState(
                        getArticleContent(art)?.state
                      ),
                    }"
                  >
                    <img
                      v-if="getTranslationLanguage(art)"
                      :alt="getTranslationLanguage(art)"
                      class="img-flag"
                      :src="getFlagPath(getTranslationLanguage(art) || '')"
                    />
                    <q-icon
                      v-else
                      name="translate"
                      size="14px"
                      class="q-ml-xs"
                    />
                  </q-badge>
                  <q-badge
                    v-if="getCSSColorState(getArticleContent(art)?.state)"
                    :style="getCSSColorState(getArticleContent(art)?.state)"
                    class="badge-state"
                    rounded
                    floating
                  ></q-badge>
                  <div class="q-gutter-xs">
                    <q-chip dense color="grey" text-color="white" size="sm">{{
                      getLabelFormatByLanguage(
                        getArticleContentWithFallback(art) as ArticleContentDto,
                        languageFilter
                      )
                    }}</q-chip>
                    <template
                      v-if="row.section.id === 0 && config.publication === 'GL'"
                    >
                      <q-chip
                        dense
                        size="sm"
                        :style="
                          getSectionCSSColors(
                            getMainSection(art.sections)!.id,
                            true
                          )
                        "
                        >{{ getSectionLabel(art) }}</q-chip
                      >
                    </template>
                    <template v-if="config.publication !== 'LLA'">
                      <q-chip
                        v-for="(country, idx) of art.countries"
                        :key="idx"
                        dense
                        outline
                        :text-color="
                          row.section.id !== 0 ? 'primary' : undefined
                        "
                        size="sm"
                        >{{ getCountryLabel(country, languageFilter) }}</q-chip
                      >
                    </template>
                    <template v-if="getSectionLLALabel(art) != ''">
                      <q-chip dense outline text-color="primary" size="sm">{{
                        getSectionLLALabel(art)
                      }}</q-chip>
                    </template>
                  </div>
                  <div class="ellipsis text-black">
                    <q-icon
                      v-if="
                        articleLocks.some((lock) => lock.articleId === art.id)
                      "
                      name="lock"
                    />
                    {{
                      getArticleContent(art)?.title ??
                      $t("article.messages.translationDoesNotExists")
                    }}
                  </div>
                  <div class="text-black">
                    <small>
                      {{
                        $t(`article.fields.countWords`, [
                          getArticleContent(art)?.wordCount,
                        ])
                      }}
                    </small>
                    &nbsp;
                    <small>
                      {{
                        $t(`article.fields.countChars`, [
                          getArticleContent(art)?.charCount.toLocaleString(),
                        ])
                      }}
                    </small>
                  </div>
                  <q-tooltip
                    class="bg-grey-3"
                    max-width="350px"
                    style="font-size: 14px; color: black"
                  >
                    <div class="q-mb-sm">
                      {{ getAuthorsName(art).join(" • ") }}
                      <template v-if="getFreelancesName(art).length">
                        &nbsp;•&nbsp;{{ getFreelancesName(art).join(" • ") }}
                      </template>
                    </div>
                    <div v-if="getTranslatorsName(art).length" class="q-mb-sm">
                      <q-icon name="translate" />
                      {{ getTranslatorsName(art).join(" • ") }}
                    </div>
                    <div class="q-mb-sm">
                      {{ getArticleContent(art)?.title }}&nbsp;<q-icon
                        v-if="getArticleContent(art)?.medias.length"
                        name="image"
                      />
                    </div>
                    <div class="q-gutter-sm">
                      <q-chip
                        :style="getCSSColorState(getArticleContent(art)?.state)"
                        size="xs"
                      >
                        {{
                          $t(`article.states.${getArticleContent(art)?.state}`)
                        }}
                      </q-chip>
                    </div>
                    <div
                      v-if="
                        articleLocks.some((lock) => lock.articleId === art.id)
                      "
                      class="q-gutter-sm"
                    >
                      <div
                        v-for="l in articleLocks.filter(
                          (l) => l.articleId === art.id
                        )"
                        :key="l.modificationType"
                      >
                        <div class="q-pa-sm col-2">
                          <q-icon name="lock" />
                          {{ l.userName }} :
                          {{ getTypeLock(l.modificationType) }} ({{
                            formatTime(l.createdAt)
                          }})
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        getArticleContent(art)?.state ===
                        WorkflowState.Relecture
                      "
                    >
                      <StateReview
                        :contributions="art.contributions"
                        :state="getArticleContent(art)?.state"
                        :language="getArticleContent(art)?.language"
                      />
                    </div>
                  </q-tooltip>
                </q-card-section>
              </q-card>
            </div>
            <div v-else>
              {{ row.section.label }}
            </div>
          </q-td>
        </template>
      </q-table>
      <q-inner-loading
        :showing="loading"
        :label="$t('messages.waiting')"
        label-class="text-teal"
        label-style="font-size: 1.1em"
        style="z-index: 10"
      />
    </q-card-section>
  </q-card>
  <DialogAddLanguage
    v-model="showDialogAddLanguage"
    :article="currentArticleStore.currentArticle"
    @add:language="onNewLanguage"
  />
  <DialogChangePublishedOn
    v-model="showDialogChangePublishedOn"
    :article="dialogChangePublishedOnArticle"
    :published-on="dialogChangePublishedOnDate"
    @update:published-on="handleDialogChangePublishedOnUpdate"
  />
</template>

<script setup lang="ts">
import { useQuasar, date, type EventBus, type QTableColumn } from "quasar";
import type {
  ArticleSearch,
  ArticleDto,
  ArticleLockDto,
  ArticleContentDto,
} from "@/types/api";
import { RightReference, WorkflowState } from "@/types/api";
import { ref, computed, onMounted, watch, inject } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { watchDebounced } from "@vueuse/core";
import { searchService } from "@/services/SearchService";
import { useConfigStore } from "@/stores/config";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useLanguageStore } from "@/stores/language";
import { useSectionStore } from "@/stores/section";
import { usePage } from "@/composables/usePage";
import { useArticleActions } from "@/composables/useArticleActions";
import { getSectionCSSColors } from "@/helpers/section";
import { getCSSColorState } from "@/helpers/workflowState";
import CustomResponsiveMenu from "@/components/Custom/CustomResponsiveMenu.vue";
import AutoRefresh from "@/components/Custom/AutoRefresh.vue";
import DialogAddLanguage from "@/components/Article/DialogAddLanguage.vue";
import DialogChangePublishedOn from "@/components/Article/DialogChangePublishedOn.vue";
import {
  getNextWeek,
  getPreviousWeek,
  getDistanceInWeek,
  getArticleContentByLanguage,
  getAuthorsName,
  getFreelancesName,
  getTranslatorsName,
  getArticlePrimary,
  getMainSection,
  getPreviousEditionDay,
  getNextEditionDay,
  needToBeTranslated,
  getTranslationLanguage,
  getLabelFormatByLanguage,
  getCountryLabel,
  getSectionLabel,
} from "@/helpers/article";
import { useLocale, formatDate, formatTime, formatErrorMessage } from "@/utils";
import InputDate from "@/components/Custom/CustomInputDate.vue";
import { RoutesName } from "@/router/routesName";
import { getFlagPath, ArticleFormat } from "@/constants";
import { lockService, getTypeLock } from "@/services/LockService";
import { dayAfterPlanningGoToNextWeek } from "@/components/Article/constant";
import { authService } from "@/services/AuthService";
import StateReview from "@/components/Article/StateReview.vue";

const $q = useQuasar();
const bus = inject<EventBus>("bus");
const props = defineProps<{
  publishedFrom?: string;
}>();
const emits = defineEmits<{
  (e: "update:publishedFrom", value: string): void;
}>();

const i18n = useI18n();
const { showPanelRight } = usePage();
const { actions } = useArticleActions();
const currentArticleStore = useCurrentArticleStore();
const { config } = storeToRefs(useConfigStore());
const { languages } = useLanguageStore();
const { language, isFr } = useLocale();
const { filterSectionsByPublication } = useSectionStore();
const today = new Date();
const loading = ref(false);
const languageFilter = ref(language.value);
const currentDate = props.publishedFrom ? new Date(props.publishedFrom) : today;
const articleLocks = ref<ArticleLockDto[]>([]);
const jumpToNextWeek =
  !props.publishedFrom &&
  (today.getDay() == 0 ||
    today.getDay() > dayAfterPlanningGoToNextWeek[config.value.publication]);

const localPublishedFrom = ref(
  currentDate
    .addDays(getDistanceInWeek(currentDate, 1) + (jumpToNextWeek ? 7 : 0))
    .toLocaleISOString()
);
const showDialogAddLanguage = ref(false);
const showDialogChangePublishedOn = ref(false);
const dialogChangePublishedOnDate = ref("");
const dialogChangePublishedOnArticle = ref<ArticleDto | undefined>(undefined);

const searchParams = computed<ArticleSearch>(() => {
  let dateFrom = new Date(localPublishedFrom.value);
  dateFrom = dateFrom.addDays(getDistanceInWeek(dateFrom, 1));
  const publishedTo = dateFrom.addDays(6);
  return {
    idBackOffice: [],
    idContentWorkflow: [],
    idWorkflow: [],
    cms: [],
    contributions: [],
    countries: [],
    formats: [],
    formatsExcluded: [ArticleFormat.Feuilleton],
    interests: [],
    sections: [],
    states: [],
    statesExcluded: [],
    statesAdvanced: [],
    texts: [],
    onlyOnTitleAndStandfirst: false,
    page: 1,
    nbByPage: 100,
    fridge: false,
    deleted: false,
    from: dateFrom.toUTCISOString(),
    to: publishedTo.toUTCISOString(),
    summaryFrom: dateFrom.toUTCISOString(),
    summaryTo: publishedTo.toUTCISOString(),
    publications: [config.value.publication],
  };
});
const daysInWeek = computed(() => {
  const startDate = new Date(localPublishedFrom.value);
  const offsetMonday = getDistanceInWeek(startDate, 1);
  const week: Record<Week, string> = {
    monday: formatDate(startDate.addDays(offsetMonday)),
    tuesday: formatDate(startDate.addDays(offsetMonday + 1)),
    wednesday: formatDate(startDate.addDays(offsetMonday + 2)),
    thursday: formatDate(startDate.addDays(offsetMonday + 3)),
    friday: formatDate(startDate.addDays(offsetMonday + 4)),
  };
  return week;
});
const sections = computed(() =>
  filterSectionsByPublication(config.value.publication, true)
);
const canAddLang = ref(
  authService.hasRight(
    RightReference.PUBLICATION_ARTICLE_CREATE_TRANSLATION,
    config.value.publication
  )
);
/**
 * Interface ArticleWithSection
 */
interface ArticleWithSection extends ArticleDto {
  mainSection: string;
  section: string;
}

/**
 * Interface ArticlesInWeek
 */
interface ArticlesInWeek {
  section: {
    id: number;
    label: string;
    weight: number;
  };
  monday?: ArticleWithSection[];
  tuesday?: ArticleWithSection[];
  wednesday?: ArticleWithSection[];
  thursday?: ArticleWithSection[];
  friday?: ArticleWithSection[];
}
/**
 * Jours de la semaine
 */
type Week = "monday" | "tuesday" | "wednesday" | "thursday" | "friday";

const columns: QTableColumn<ArticlesInWeek>[] = [
  {
    name: "section",
    label: "Rubrique",
    align: "left",
    field: (row) => row.section,
  },
  {
    name: "monday",
    label: i18n.t("common.labels.monday"),
    align: "center",
    field: (row) => row.monday,
  },
  {
    name: "tuesday",
    label: i18n.t("common.labels.tuesday"),
    align: "center",
    field: (row) => row.tuesday,
  },
  {
    name: "wednesday",
    label: i18n.t("common.labels.wednesday"),
    align: "center",
    field: (row) => row.wednesday,
  },
  {
    name: "thursday",
    label: i18n.t("common.labels.thursday"),
    align: "center",
    field: (row) => row.thursday,
  },
  {
    name: "friday",
    label: i18n.t("common.labels.friday"),
    align: "center",
    field: (row) => row.friday,
  },
];

let searchResult: ArticleDto[] = [];
const rows = ref<ArticlesInWeek[]>([]);

onMounted(() => {
  searchArticles();
  if (bus) bus.on("refresh", searchArticles);
});
watch(
  () => config.value.publication,
  () => searchArticles()
);
watch(languageFilter, () => constructRows());
watchDebounced(
  localPublishedFrom,
  () => {
    emits("update:publishedFrom", formatDate(localPublishedFrom.value));
    searchArticles();
  },
  { debounce: 500 }
);
/**
 *
 */
async function searchArticles() {
  loading.value = true;
  try {
    const { data } = await searchService.searchArticle([searchParams.value]);
    searchResult = data;

    if (data.length === 0) {
      rows.value = [];
      loading.value = false;
      return;
    }

    calculateLock(data.map((a) => a.id));

    constructRows();

    loading.value = false;
  } catch (e) {
    console.error(e);
    rows.value = [];
    loading.value = false;
  }
}
/**
 * Permet de construire les lignes du tableau
 */
function constructRows() {
  loading.value = true;
  console.log("constructRows");
  const arrDaysInWeek = Object.entries(daysInWeek.value);
  const articlesPrimariesInWeek = getArticlesPrimariesInWeek(searchResult);
  const articlesPrimariesInWeekList = Object.values(articlesPrimariesInWeek)
    .filter((article) => article[0])
    .flat();
  const restArticles = searchResult.filter(
    (article) =>
      !articlesPrimariesInWeekList.some(
        (articlePrimary) => articlePrimary.id === article.id
      )
  );

  const results: ArticlesInWeek[] = [];
  const groupedArticlesInWeek = groupArticlesBySectionInWeek(
    restArticles,
    arrDaysInWeek
  );

  for (const group of Object.entries(groupedArticlesInWeek)) {
    results.push(group[1]);
  }

  rows.value = [articlesPrimariesInWeek, ...results].sort((a, b) =>
    a.section.weight > b.section.weight ? 1 : -1
  );
  loading.value = false;
}
/**
 *
 */
function getArticlesPrimariesInWeek(articles: ArticleDto[]) {
  return {
    section: {
      id: 0,
      label: i18n.t("article.fields.event"),
      weight: 0,
    },
    ...Object.fromEntries(
      Object.entries(daysInWeek.value).map(([day, dateDay]) => [
        day,
        [getArticlePrimary(articles, dateDay, languageFilter.value)].filter(
          Boolean
        ),
      ])
    ),
  };
}
/**
 *
 */
function groupArticlesBySectionInWeek(
  articles: ArticleDto[],
  arrDaysInWeek: [string, string][]
) {
  const groupedArticles: Record<number, ArticlesInWeek> = {};

  const articlesWithMainSection = articles.map((a) => {
    return {
      ...a,
      ...getMainSectionLabel(a),
    };
  });

  for (const section of sections.value) {
    const sectionId = section.id;
    const sectionLabel = isFr.value
      ? section.labelFr
      : section.labelEn ?? section.labelFr;

    const articlesInSection = articlesWithMainSection.filter(
      (d) => d.mainSection.toUpperCase() == sectionLabel.toUpperCase()
    );

    if (articlesInSection.length === 0) continue;

    articlesInSection.forEach((article) => {
      let publishedOn = formatDate(article.publishedOn as string);

      if (article.summaryOf) publishedOn = formatDate(article.summaryOf);

      if (
        languageFilter.value != article.idMainLanguage &&
        article.publishedOnTranslation
      ) {
        publishedOn = formatDate(article.publishedOnTranslation);

        if (article.summaryOfTranslation)
          publishedOn = formatDate(article.summaryOfTranslation);
      }

      const dayName = arrDaysInWeek.find((d) => d[1] === publishedOn);

      if (!dayName) return;
      const dayKey = dayName[0];

      if (!groupedArticles[sectionId]) {
        groupedArticles[sectionId] = {
          section: {
            id: sectionId,
            label: sectionLabel,
            weight: section.weight ?? 99999,
          },
        };
      }

      if (!groupedArticles[sectionId][dayKey]) {
        groupedArticles[sectionId][dayKey] = [];
      }

      groupedArticles[sectionId][dayKey].push(article);
    });
  }

  // pour chaque section, on trie les articles par leur score
  for (const section of Object.values(groupedArticles)) {
    for (const day of Object.keys(section)) {
      if (day === "section") continue;
      section[day] = section[day].sort((a, b) => {
        return b.score - a.score;
      });
    }
  }

  return groupedArticles;
}
/**
 * Permet de récupérer les verrous des articles de la liste
 */
async function calculateLock(ids: number[]) {
  articleLocks.value = await lockService.getList(ids);
}
/**
 *
 */
function handlePreviousWeek() {
  localPublishedFrom.value = getPreviousWeek(
    new Date(localPublishedFrom.value)
  )[0];
}
/**
 *
 */
function handleNextWeek() {
  localPublishedFrom.value = getNextWeek(new Date(localPublishedFrom.value))[0];
}
/**
 *
 */
function formatDateForHeaderCell(dayName: Week) {
  const defaultMask = "DD/MM";
  return date.formatDate(daysInWeek.value[dayName], defaultMask);
}
/** Récupère le contenu de l'article selon la langue choisie mais avec un fallback sur le premier contenu si la langue n'est pas disponible */
function getArticleContentWithFallback(article: ArticleDto) {
  return getArticleContent(article) ?? article.contents[0];
}
/** Récupère le contenu de l'article selon la langue choisie */
function getArticleContent(article: ArticleDto) {
  return getArticleContentByLanguage(article.contents, languageFilter.value);
}
/**
 * Permet de savoir si l'article doit être traduit
 */
function getActions(article: ArticleDto) {
  const actions = {
    canTranslate:
      needToBeTranslated(article, languageFilter.value) && canAddLang.value,
    changeDate:
      !article.contents.some((c) => c.online) &&
      authService.hasRight(
        RightReference.CHANGE_PUBLISHED_ON,
        config.value.publication
      ),
    noAction: false,
  };
  actions.noAction = !Object.values(actions).some((a) => a);

  return actions;
}
/**
 * Recupère les option disponible pour changer la date de publication
 */
function getChangePublicationDateOptions(article: ArticleDto) {
  const nbBefore = 4;
  let publishedOn = article.publishedOn;
  if (
    languageFilter.value != article.idMainLanguage &&
    article.publishedOnTranslation
  )
    publishedOn = article.publishedOnTranslation;

  if (!publishedOn) return [];

  let date = new Date(publishedOn);
  let option = date;
  for (let i = 0; i < nbBefore; i++) {
    option = getPreviousEditionDay(option);
  }
  const options = [] as { date: Date; label: string }[];
  const today = new Date();

  while (options.length < nbBefore * 2) {
    if (option > today && option.getDate() != date.getDate()) {
      options.push({
        date: option,
        label: formatDateForOption(option),
      });
    }
    option = getNextEditionDay(option);
  }

  return options;
}
/** */
function handleShowDialogChangePublishedOn(article: ArticleDto) {
  const mainLanguage = article.idMainLanguage == languageFilter.value;

  dialogChangePublishedOnDate.value = article.publishedOn ?? "";
  if (!mainLanguage && article.publishedOnTranslation)
    dialogChangePublishedOnDate.value = article.publishedOnTranslation;

  dialogChangePublishedOnArticle.value = article;

  showDialogChangePublishedOn.value = true;
}
/** */
function handleDialogChangePublishedOnUpdate(newValue: {
  publishedOn: string;
  article: ArticleDto | undefined;
}) {
  if (!newValue.publishedOn || !newValue.article) return;

  handleChangeDate(newValue.article, new Date(newValue.publishedOn));
}
/**
 * Change la date de publication d'un article
 */
async function handleChangeDate(article: ArticleDto, date: Date) {
  const mainLanguage = article.idMainLanguage == languageFilter.value;
  loading.value = true;

  try {
    const result = await actions.updatePublishedOn(
      article.id,
      mainLanguage,
      date
    );
    loading.value = false;

    if (result) searchArticles();
  } catch (e) {
    console.error(e);
    $q.notify({
      type: "negative",
      message: formatErrorMessage(
        e,
        "exceptions.ArticleSaveException.SaveError"
      ),
    });
    loading.value = false;
  }
}
/**
 * Format la date de manière inteligente pour l'affichage
 */
function formatDateForOption(date: Date) {
  return Intl.DateTimeFormat(isFr.value ? "fr-FR" : "en-US", {
    month: "numeric",
    day: "numeric",
    weekday: "long",
  }).format(date);
}
/**
 *
 */
function handleShowSettings(articleId: number) {
  actions.findArticle(articleId, false, false, () => {
    showPanelRight.value = true;
    currentArticleStore.$state.lang = languageFilter.value;
  });
}
/**
 *
 */
function getMainSectionLabel(article: ArticleDto) {
  let mainSectionLabel: string;
  let sectionLabel: string;
  const mainSection = getMainSection(article.sections, true);
  const section = getMainSection(article.sections, false);
  if (!mainSection) mainSectionLabel = "";
  else
    mainSectionLabel =
      mainSection?.labelEn && !isFr.value
        ? mainSection?.labelEn
        : mainSection.labelFr;
  if (!section) sectionLabel = "";
  else
    sectionLabel =
      section?.labelEn && !isFr.value ? section?.labelEn : section.labelFr;
  return {
    mainSection: mainSectionLabel,
    section: sectionLabel,
  };
}
/**
 *
 */
function getSectionLLALabel(article: ArticleWithSection) {
  if (config.value.publication !== "LLA" || !article.section) return "";
  const labelSplitted = article.section.split("/");
  return labelSplitted.length > 1 ? labelSplitted[1] : labelSplitted[0];
}
/**
 *
 */
function GetArticlesContentListForCount(day: string) {
  return [
    ...rows.value
      .filter((r) => r[day]?.length)
      .map(
        (r) =>
          r[day]?.map((s) =>
            s.contents
              .filter(
                (c) =>
                  c.language == languageFilter.value &&
                  c.format !== ArticleFormat.Feuilleton &&
                  c.state !== WorkflowState.Invalide
              )
              .flat()
          )
      )
      .flat(),
  ].flat();
}
/** Compte le nombre d'articles pour une date donnée */
function getArticlesCountByDate(day: string) {
  const listArticles = GetArticlesContentListForCount(day);
  return listArticles.length;
}
/** Compte le nombre de mots pour une date donnée */
function getWordsCountByDate(day: string) {
  const listArticles = GetArticlesContentListForCount(day);
  return listArticles
    .map((c) => c.wordCount)
    .reduce((partialSum, a) => partialSum + a, 0);
}
/** Compte le nombre de caractères pour une date donnée */
function getCharactersCountByDate(day: string) {
  const listArticles = GetArticlesContentListForCount(day);
  return listArticles
    .map((c) => c.charCount)
    .reduce((partialSum, a) => partialSum + a, 0);
}

/**
 *
 */
function handleCreateTranslation(articleId: number) {
  actions.findArticle(articleId, false, false, () => {
    showDialogAddLanguage.value = true;
  });
}

/**
 * Fonction appelé lors du choix de la nouvelle langue à rajouter à l'article
 */
function onNewLanguage() {
  showDialogAddLanguage.value = false;
  searchArticles();
}
</script>
<style scoped lang="scss">
.table-sticky-header {
  max-height: calc(100vh - 180px);
  tr th {
    position: sticky;
    z-index: 2;
  }
  thead tr:last-child th {
    top: 48px;
    z-index: 3;
  }

  thead tr:first-child th {
    top: 0;
    z-index: 1;
    height: 100%;
    &:not(:first-child) {
      div:first-child {
        text-align: center;
        padding-left: 16px;
      }
    }
  }
  tr:first-child th:first-child {
    z-index: 3;
    text-align: left;
  }
  td:first-child {
    z-index: 1;
  }
  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
}
.card-selected {
  border: var(--q-primary) solid 4px;
}
.card-selected div.ellipsis {
  font-weight: bold;
}
.badge-state {
  height: 20px;
  width: 20px;
}
.badge-translate-margin {
  margin-right: 22px;
}
.img-flag {
  width: 15px;
  height: 15px;
}
</style>
