<template>
  <q-card bordered>
    <q-card-actions align="around">
      <q-checkbox
        v-for="c in longFormatInfo?.releaseConfigurations ?? []"
        :key="c.key"
        v-model="selectedConfigurations"
        :label="getConfigurationLabel(c)"
        :val="c.key"
        :disable="loading"
      />
      <q-btn-dropdown
        v-show="isUserOfPublication"
        color="primary"
        :label="$t('longFormat.actions.createArticle')"
        :disable="loading"
      >
        <q-list>
          <q-item
            v-for="format in formats"
            :key="format.format"
            v-close-popup
            clickable
            @click="createArticle(format.format)"
          >
            <q-item-section>
              <q-item-label>{{ format.label }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <div>
        <q-btn-dropdown
          v-show="isUserOfPublication"
          color="primary"
          :label="$t('longFormat.actions.createSeries')"
          :disable="loading || !canCreateSeries"
        >
          <q-list>
            <q-item
              v-for="format in formats"
              :key="format.format"
              v-close-popup
              clickable
              @click="createSeries(format.format)"
            >
              <q-item-section>
                <q-item-label>{{ format.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-tooltip v-if="!canCreateSeries" bottom color="red">{{
          $t("longFormat.dialogs.messages.warningAskToManager")
        }}</q-tooltip>
      </div>
      <AutoRefresh
        :auto-refresh="true"
        :auto-refresh-time-in-seconds="60"
        :refresh-function="() => searchArticles()"
        :refresh-condition-checker="() => true"
        :auto-refresh-toggle="true"
      />
    </q-card-actions>
    <q-card-actions v-if="computedAuthorsInError.length" align="around">
      <div class="sample-error-deadline-author">
        {{
          $t("longFormat.dialogs.messages.errorDeadlineTooClose", {
            count: computedAuthorsInError.length,
            userNames: computedAuthorsInError.join(", "),
          })
        }}
      </div>
    </q-card-actions>
    <q-card-actions
      v-for="error in computedArticlesSameDateError"
      :key="error.format"
      align="around"
    >
      <div class="sample-error-same-date">
        {{
          $t("longFormat.dialogs.messages.errorTwoArticlesAtSameDate", {
            count: error.dates.length,
            format: error.format,
            dates: error.dates
              .map((d) => date.formatDate(d, "DD MMMM"))
              .join(", "),
          })
        }}
      </div>
    </q-card-actions>
    <q-card-actions
      v-for="error in computedAvailableDatesError"
      :key="error.label"
      align="around"
    >
      {{
        $t("longFormat.dialogs.messages.errorArticleMissingAtDate", {
          count: error.dates.length,
          format: error.label,
          dates: error.dates
            .map((d) => date.formatDate(d, "DD MMMM"))
            .join(", "),
        })
      }}
    </q-card-actions>
    <q-card-section>
      <q-table
        class="table-sticky-header"
        flat
        bordered
        hide-pagination
        :pagination="{ rowsPerPage: 100 }"
        :columns="columns"
        :rows="rows"
      >
        <template #header-cell="{ col }">
          <q-th class="table-header">
            <div>
              {{ headers[col.name] }}
            </div>
          </q-th>
        </template>
        <template #body-cell="{ row, col }">
          <q-td
            style="max-width: 250px; white-space: normal; vertical-align: top"
            :style="col.name == 'section' ? 'border-right-width: 1px' : null"
          >
            <div>
              <q-card
                v-for="art of row[col.name]"
                :key="art.id"
                :class="{
                  'q-mb-sm': true,
                  'cursor-pointer': true,
                  'card-selected':
                    art.id === currentArticleStore.currentArticle.id,
                }"
                :bordered="false"
                :style="getCardStyle(art)"
                @click="handleShowSettings(art.id)"
              >
                <q-menu touch-position context-menu class="menu-above-tooltip">
                  <q-list dense style="min-width: 100px">
                    <q-item
                      v-if="
                        getActions(art).hasLongFormatRight &&
                        isArticleDoubleAtDate(art)
                      "
                      v-close-popup
                      clickable
                      dense
                      @click="confirmArticleAtDate(art)"
                    >
                      <q-item-section>{{
                        $t("longFormat.actions.confirmArticleAtDate")
                      }}</q-item-section>
                    </q-item>
                    <q-item
                      v-if="getActions(art).hasLongFormatRight"
                      v-close-popup
                      clickable
                      dense
                      @click="handleShowDialogChangePublishedOn(art)"
                    >
                      <q-item-section>{{
                        $t("longFormat.actions.choseDate")
                      }}</q-item-section>
                    </q-item>
                    <q-item
                      v-if="getActions(art).hasLongFormatRight"
                      v-close-popup
                      clickable
                      dense
                      @click="handleMoveSeries(art, true)"
                    >
                      <q-item-section>{{
                        $t("longFormat.actions.pushSeries")
                      }}</q-item-section>
                    </q-item>
                    <q-item
                      v-if="getActions(art).hasLongFormatRight"
                      v-close-popup
                      clickable
                      dense
                      @click="handleMoveSeries(art, false)"
                    >
                      <q-item-section>{{
                        $t("longFormat.actions.pullSeries")
                      }}</q-item-section>
                    </q-item>
                    <q-item
                      v-if="getActions(art).hasLongFormatRight"
                      v-close-popup
                      clickable
                      dense
                      @click="handleRemoveArticle(art, true)"
                    >
                      <q-item-section>{{
                        $t("longFormat.actions.deleteArticle")
                      }}</q-item-section>
                    </q-item>
                    <q-item
                      v-if="getActions(art).hasLongFormatRight"
                      v-close-popup
                      clickable
                      dense
                      @click="handleRemoveArticle(art, false)"
                    >
                      <q-item-section>{{
                        $t("longFormat.actions.freezeArticle")
                      }}</q-item-section>
                    </q-item>
                    <q-item
                      v-if="getActions(art).noAction"
                      v-close-popup
                      clickable
                    >
                      <q-item-section>{{
                        $t("planning.actions.noAction")
                      }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
                <q-card-section class="q-pa-sm">
                  <q-badge
                    v-if="getCSSColorState(getArticleContent(art)?.state)"
                    :style="getCSSColorState(getArticleContent(art)?.state)"
                    class="badge-state"
                    rounded
                    floating
                  ></q-badge>
                  <div class="q-gutter-xs">
                    <q-chip dense color="grey" text-color="white" size="sm">{{
                      getLabelFormatByLanguage(
                        getArticleContent(art) as ArticleContentDto,
                        language
                      )
                    }}</q-chip>
                    <template v-if="config.publication === 'GL'">
                      <q-chip
                        dense
                        size="sm"
                        :style="
                          getSectionCSSColors(
                            getMainSection(art.sections)!.id,
                            true
                          )
                        "
                        >{{ getSectionLabel(art) }}</q-chip
                      >
                    </template>
                  </div>
                  <div class="ellipsis text-color-normal">
                    <q-icon
                      v-if="
                        articleLocks.some((lock) => lock.articleId === art.id)
                      "
                      name="lock"
                    />
                    {{ getAuthorsName(art).join(" • ") }}
                    <br />
                    {{
                      getArticleContent(art)?.title ??
                      $t("article.messages.translationDoesNotExists")
                    }}
                    <br />
                    <span>{{ getPublishedOn(art) }}</span>
                    <span :style="getDeadlineStyle(art)">{{
                      getDeadline(art)
                    }}</span>
                  </div>
                  <q-tooltip
                    class="bg-grey-3"
                    max-width="350px"
                    style="font-size: 14px; color: black"
                  >
                    <div class="q-mb-sm">
                      {{ getAuthorsName(art).join(" • ") }}
                      <template v-if="getFreelancesName(art).length">
                        &nbsp;•&nbsp;{{ getFreelancesName(art).join(" • ") }}
                      </template>
                    </div>
                    <div v-if="getTranslatorsName(art).length" class="q-mb-sm">
                      <q-icon name="translate" />
                      {{ getTranslatorsName(art).join(" • ") }}
                    </div>
                    <div class="q-mb-sm">
                      {{ getArticleContent(art)?.title }}&nbsp;<q-icon
                        v-if="getArticleContent(art)?.medias.length"
                        name="image"
                      />
                    </div>
                    <div class="q-gutter-sm">
                      <q-chip
                        :style="getCSSColorState(getArticleContent(art)?.state)"
                        size="xs"
                      >
                        {{
                          $t(`article.states.${getArticleContent(art)?.state}`)
                        }}
                      </q-chip>
                    </div>
                    <div
                      v-if="
                        articleLocks.some((lock) => lock.articleId === art.id)
                      "
                      class="q-gutter-sm"
                    >
                      <div
                        v-for="l in articleLocks.filter(
                          (l) => l.articleId === art.id
                        )"
                        :key="l.modificationType"
                      >
                        <div class="q-pa-sm col-2">
                          <q-icon name="lock" />
                          {{ l.userName }} :
                          {{ getTypeLock(l.modificationType) }} ({{
                            formatTime(l.createdAt)
                          }})
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        getArticleContent(art)?.state ===
                        WorkflowState.Relecture
                      "
                    >
                      <StateReview
                        :contributions="art.contributions"
                        :state="getArticleContent(art)?.state"
                        :language="getArticleContent(art)?.language"
                      />
                    </div>
                  </q-tooltip>
                </q-card-section>
              </q-card>
            </div>
          </q-td>
        </template>
      </q-table>
      <q-inner-loading
        :showing="loading"
        :label="$t('messages.waiting')"
        label-class="text-teal"
        label-style="font-size: 1.1em"
        style="z-index: 10"
      />
    </q-card-section>
  </q-card>
  <DialogChangePublishedOn
    v-model="showDialogChangePublishedOn"
    :article="dialogChangePublishedOnArticle"
    :published-on="dialogChangePublishedOnDate"
    :options="availableDates"
    @update:published-on="handleDialogChangePublishedOnUpdate"
  />
  <DialogAddSeries
    v-model="showDialogAddSeries"
    :format="selectedFormatForSeries"
    :label-format="
      formats.find((f) => f.format == selectedFormatForSeries)?.label
    "
    :long-format-info="longFormatInfo"
    @update:authors="handleDialogAddSeries"
  />
</template>

<script setup lang="ts">
import {
  useQuasar,
  Dark,
  date,
  type EventBus,
  type QTableColumn,
} from "quasar";
import type {
  ArticleContentDto,
  ArticleDto,
  ArticleLockDto,
  LongFormatInformation,
  LongFormatReleaseConfiguration,
  SeriesArticle,
} from "@/types/api";
import { ContributionType, RightReference, WorkflowState } from "@/types/api";
import { ref, computed, onMounted, watch, inject } from "vue";
import { storeToRefs } from "pinia";
import { useConfigStore } from "@/stores/config";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { usePage } from "@/composables/usePage";
import { useArticleActions } from "@/composables/useArticleActions";
import { getSectionCSSColors } from "@/helpers/section";
import { getFormatOptions } from "@/helpers/format";
import { getCSSColorState } from "@/helpers/workflowState";
import DialogChangePublishedOn from "@/components/Article/DialogChangePublishedOn.vue";
import DialogAddSeries from "@/components/PlanningLongFormat/DialogAddSeries.vue";
import AutoRefresh from "@/components/Custom/AutoRefresh.vue";
import {
  getArticleContentByLanguage,
  getMainSection,
  getAuthorsName,
  getFreelancesName,
  getTranslatorsName,
  getLabelFormatByLanguage,
  getSectionLabel,
} from "@/helpers/article";
import { useLocale, formatTime, formatErrorMessage, getToday } from "@/utils";
import { lockService, getTypeLock } from "@/services/LockService";
import { authService } from "@/services/AuthService";
import StateReview from "@/components/Article/StateReview.vue";
import { longFormatService } from "@/services/LongFormatService";
import { i18n } from "@/i18n";
import { useUserStore } from "@/stores/user";
import { getAvailableDates } from "@/helpers/longFormatInformation";

const $q = useQuasar();
const bus = inject<EventBus>("bus");
const { showPanelRight } = usePage();
const { actions } = useArticleActions();
const currentArticleStore = useCurrentArticleStore();
const { config } = storeToRefs(useConfigStore());
const { language } = useLocale();
const loading = ref(false);
const articleLocks = ref<ArticleLockDto[]>([]);
const showDialogChangePublishedOn = ref(false);
const showDialogAddSeries = ref(false);
const dialogChangePublishedOnDate = ref("");
const dialogChangePublishedOnArticle = ref<ArticleDto | undefined>(undefined);
const longFormatInfo = ref<LongFormatInformation | undefined>(undefined);
const selectedFormatForSeries = ref<string>("");
const selectedConfigurations = ref<string[]>([]);
const availableDates = ref<string[] | undefined>([]);
const formats = computed(() => {
  const result: FormatWithConfigAndDates[] = [];
  if (longFormatInfo.value == undefined) return result;

  const options = getFormatOptions({
    publication: config.value.publication,
    allOption: false,
  });

  const info = longFormatInfo.value;

  for (const config of info.releaseConfigurations) {
    if (!result.some((r) => r.format == config.idFormat)) {
      result.push({
        format: config.idFormat,
        releaseConfig: config,
        label: options.find((o) => o.value == config.idFormat)?.label ?? "",
        dates: config.availableDates,
      });
    } else {
      const index = result.findIndex((r) => r.format == config.idFormat);
      result[index].dates = result[index].dates.concat(config.availableDates);
    }
  }

  return result;
});
const { findUserLight } = useUserStore();

watch(selectedConfigurations, () => {
  constructRows();
});
const headers = (function () {
  const now = new Date();
  now.setDate(1);
  const months = {};
  for (let i = 1; i <= 6; i++) {
    months[`month${i}`] = date.formatDate(now, "MMMM");
    now.setMonth(now.getMonth() + 1);
  }
  return months;
})();

/** Interface ArticleWithSection */
interface FormatWithConfigAndDates {
  format: string;
  label: string;
  dates: string[];
  releaseConfig: LongFormatReleaseConfiguration;
}

/**
 * Interface ArticlesInWeek
 */
interface ArticlesInMonth {
  month1?: ArticleDto[];
  month2?: ArticleDto[];
  month3?: ArticleDto[];
  month4?: ArticleDto[];
  month5?: ArticleDto[];
  month6?: ArticleDto[];
}

const columns: QTableColumn<ArticlesInMonth>[] = [
  {
    name: "month1",
    label: "",
    align: "center",
    field: (row) => row.month1,
  },
  {
    name: "month2",
    label: "",
    align: "center",
    field: (row) => row.month2,
  },
  {
    name: "month3",
    label: "",
    align: "center",
    field: (row) => row.month3,
  },
  {
    name: "month4",
    label: "",
    align: "center",
    field: (row) => row.month4,
  },
  {
    name: "month5",
    label: "",
    align: "center",
    field: (row) => row.month5,
  },
  {
    name: "month6",
    label: "",
    align: "center",
    field: (row) => row.month6,
  },
];

const rows = ref<ArticlesInMonth[]>([]);

const isUserOfPublication = computed(() => {
  return (
    authService.isRedactor(config.value.publication) ||
    authService.isEditor(config.value.publication)
  );
});

const canCreateSeries = computed(() => {
  return authService.hasRight(
    RightReference.MANAGE_LONG_FORMAT_SERIES,
    config.value.publication
  );
});

const computedAvailableDatesError = computed(() => {
  return selectedConfigurations.value
    .map((c) => {
      const conf = longFormatInfo.value?.releaseConfigurations.find(
        (rc) => rc.key === c
      );
      const maxDate = new Date().addMonths(2);
      return {
        label: getConfigurationLabel(conf),
        dates: getAvailableDates(longFormatInfo.value, c).filter(
          (d) => new Date(d) < maxDate
        ),
      };
    })
    .filter((c) => c.dates.length > 0);
});

const computedAuthorsInError = computed(() => {
  return deadlineByAuthor.value
    .filter((d) => d.articlesInError.length > 0)
    .map((d) => d.authorName);
});

const computedArticlesSameDateError = computed(() => {
  const result: {
    format: string;
    dates: string[];
  }[] = [];
  if (longFormatInfo.value == undefined) return result;
  if (longFormatInfo.value.articles.length === 0) return result;

  longFormatInfo.value.articles.reduce((acc, a) => {
    const c = a.contents[0];
    const entry = acc.find((r) => r.format === c.format) ?? {
      format: c.format,
      dates: [],
    };
    if (entry.dates.length === 0) acc.push(entry);

    entry.dates.push(a.publishedOn?.split("T")[0] ?? "");

    return acc;
  }, result);

  return result
    .map((g) => {
      return {
        format: g.format,
        dates: getUniqueDuplicates(g.dates) as string[],
      };
    })
    .filter((g) => g.dates.length > 0);
});

const deadlineByAuthor = computed(() => {
  const result: {
    authorId: string;
    authorName: string;
    deadlines: {
      id: number;
      date: number;
    }[];
    articlesInError: number[];
  }[] = [];

  if (longFormatInfo.value == undefined) return result;
  if (longFormatInfo.value.articles.length === 0) return result;

  const orderOn = longFormatInfo.value.articles.some(
    (a) => a.deadline === undefined
  )
    ? "publishedOn"
    : "deadline";

  longFormatInfo.value.articles.reduce((acc, a) => {
    a.contributions
      .filter((c) => c.type === ContributionType.Author)
      .forEach(({ idContributor }) => {
        let entry = acc.find((r) => r.authorId === idContributor);

        if (!entry) {
          acc.push({
            authorId: idContributor,
            authorName: getUserName(idContributor),
            deadlines: [],
            articlesInError: [],
          });
          entry = acc[acc.length - 1];
        }
        entry.deadlines.push({
          id: a.id,
          date: new Date(a[orderOn] as string).getTime(),
        });
      });
    return acc;
  }, result);

  let delayToDetectError = 0;
  if (config.value.publication === "AI") delayToDetectError = 40;
  if (config.value.publication === "IO") delayToDetectError = 7;
  if (config.value.publication === "LLA") delayToDetectError = 15;

  result.forEach((r) => {
    r.deadlines.sort((a, b) => a.date - b.date);
    checkErrorOnDeadline(r, delayToDetectError);
  });

  return result;
});

watch(
  () => config.value.publication,
  async () => {
    selectedConfigurations.value.length = 0;
    await searchArticles();
    setDefaultSelectedFormats();
  }
);

onMounted(async () => {
  await searchArticles();
  setDefaultSelectedFormats();

  if (bus) bus.on("refresh", searchArticles);
});

/** Permet de définir les formats sélectionnés par défaut */
function setDefaultSelectedFormats() {
  selectedConfigurations.value =
    longFormatInfo.value?.releaseConfigurations.map((r) => r.key) ?? [];
}

/** Construit le Label de la configuration */
function getConfigurationLabel(
  config: LongFormatReleaseConfiguration | undefined
) {
  if (!config) return "";
  if (!longFormatInfo.value) return config.idFormat;

  if (
    longFormatInfo.value.releaseConfigurations.filter(
      (rc) => rc.idFormat == config.idFormat
    ).length > 1
  ) {
    return i18n.t(`longFormat.dialogs.messages.labelFormatSimple`, {
      day: i18n.t(`common.dayOfWeek.${config.dayOfWeek}`),
      format: config.idFormat,
    });
  }

  return config.idFormat;
}

/** Charge les configs et les articles de long format */
async function searchArticles() {
  if (showDialogChangePublishedOn.value || showDialogAddSeries.value) return;

  loading.value = true;
  try {
    longFormatInfo.value = await longFormatService.get(
      config.value.publication
    );

    if (longFormatInfo.value == undefined) {
      rows.value = [];
      loading.value = false;
      return;
    }

    calculateLock(longFormatInfo.value.articles.map((a) => a.id));

    constructRows();

    loading.value = false;
  } catch (e) {
    console.error(e);
    rows.value = [];
    loading.value = false;
  }
}
/** Permet de construire les lignes du tableau */
function constructRows() {
  loading.value = true;
  console.log("constructRows");

  const articles =
    longFormatInfo.value?.releaseConfigurations
      .filter((rc) => selectedConfigurations.value.includes(rc.key))
      .map((rc) => rc.articles)
      .flat() ?? [];
  const row: ArticlesInMonth = {};

  const startDate = getToday(0);
  startDate.setDate(1);

  for (let i = 1; i <= 6; i++) {
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);
    row[`month${i}`] = articles
      .filter(
        (a) =>
          new Date(a.publishedOn as string) >= startDate &&
          new Date(a.publishedOn as string) < endDate
      )
      .sort(
        (a, b) =>
          new Date(a.publishedOn as string).getTime() -
          new Date(b.publishedOn as string).getTime()
      );
    startDate.setMonth(startDate.getMonth() + 1);
  }
  rows.value = [row];

  loading.value = false;
}
/** Permet de récupérer les verrous des articles de la liste */
async function calculateLock(ids: number[]) {
  articleLocks.value = await lockService.getList(ids);
}
/** Retourn le contenu de l'article selon la langue selectionnee */
function getArticleContent(article: ArticleDto) {
  return getArticleContentByLanguage(article.contents, article.idMainLanguage);
}

/** Demande de le date de parution de l'article */
async function createArticle(format: string) {
  await actions.createArticle();
  currentArticleStore.currentArticle.contents[0].format = format;
  const f = formats.value.find((f) => f.format == format);
  if (!f) return;

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  let availableDates = f.dates
    .filter((d) => new Date(d) > tomorrow)
    .map((d) => d.split("T")[0]);
  const usedDates = longFormatInfo.value?.articles
    .filter((a) => a.contents.some((c) => c.format == format))
    .map((a) => a.publishedOn?.split("T")[0]);

  if (usedDates) {
    availableDates = availableDates.filter(
      (d) => !usedDates.some((ud) => ud == d)
    );
  }
  bus?.emit("formSimpleArticle:setBaseParam", {
    format: format,
    availableDates: availableDates.map((d) => d.replaceAll("-", "/")),
  });
}

/** Demande de le date de parution de l'article */
async function createSeries(format: string) {
  currentArticleStore.currentArticle.contents[0].format = format;
  const f = formats.value.find((f) => f.format == format);
  if (!f) return;
  selectedFormatForSeries.value = format;
  showDialogAddSeries.value = true;
}

/** Retourne la date formaté */
function getPublishedOn(article: ArticleDto) {
  return i18n.t("longFormat.publishedOn", {
    date: date.formatDate(article.publishedOn as string, "ddd. D"),
  });
}
/** Retourne la deadline */
function getDeadline(article: ArticleDto) {
  if (!article.deadline) return "";
  return i18n.t("longFormat.deadline", {
    date: date.formatDate(article.deadline, "ddd. D"),
  });
}
/** Retourne le style pour la deadline */
function getDeadlineStyle(article: ArticleDto) {
  if (!article.deadline) return "";

  const state = getArticleContent(article)?.state;
  if (!state) return "";

  if (new Date() < new Date(article.deadline)) return "";

  if ([WorkflowState.Pitch, WorkflowState.Redaction].includes(state))
    return "color: red";

  return "";
}
/** Permet de savoir si l'article doit être traduit */
function getActions(article: ArticleDto) {
  const actions = {
    hasLongFormatRight:
      !article.contents.some((c) => c.online) &&
      authService.hasRight(
        RightReference.MANAGE_LONG_FORMAT_SERIES,
        config.value.publication
      ),
    noAction: false,
  };
  actions.noAction = !Object.values(actions).some((a) => a);

  return actions;
}

/** Permet de pousser toute la série à partir de cet article */
async function handleMoveSeries(article: ArticleDto, push: boolean) {
  if (!article) return;

  const i18Key = push ? "pushSeries" : "pullSeries";

  $q.dialog({
    title: i18n.t(`longFormat.dialogs.${i18Key}Title`),
    message: i18n.t(`longFormat.dialogs.${i18Key}Text`),
    cancel: true,
    persistent: true,
  }).onOk(async () => {
    loading.value = true;

    try {
      await longFormatService.moveSeries(
        article.publication,
        article.id,
        article.publishedOn as string,
        push
      );
      loading.value = false;

      searchArticles();
    } catch (e) {
      console.error(e);
      $q.notify({
        type: "negative",
        message: formatErrorMessage(
          e,
          "exceptions.ArticleSaveException.SaveError"
        ),
      });
      loading.value = false;
    }
  });
}

/** Permet de retirer un article (soit le supprimer soit le passer en frigo) */
async function handleRemoveArticle(
  article: ArticleDto,
  deleteArticle: boolean
) {
  if (!article) return;

  const i18Key = deleteArticle ? "deleteArticle" : "freezeArticle";

  $q.dialog({
    title: i18n.t(`longFormat.dialogs.${i18Key}Title`),
    message: i18n.t(`longFormat.dialogs.${i18Key}Text`),
    cancel: true,
    persistent: true,
  }).onOk(async () => {
    try {
      loading.value = true;

      await longFormatService.removeArticle(
        article.publication,
        article.id,
        article.publishedOn as string,
        deleteArticle
      );
      loading.value = false;

      searchArticles();
    } catch (e) {
      console.error(e);
      $q.notify({
        type: "negative",
        message: formatErrorMessage(
          e,
          "exceptions.ArticleSaveException.SaveError"
        ),
      });
      loading.value = false;
    }
  });
}

/** Permet de confirmer l'article a une date lorsqu'il est en conflit avec un autre article */
async function confirmArticleAtDate(article: ArticleDto) {
  if (!article) return;

  $q.dialog({
    title: i18n.t(`longFormat.dialogs.confirmArticleTitle`),
    message: i18n.t(`longFormat.dialogs.confirmArticleText`, {
      title: article.contents[0].title,
      date: date.formatDate(
        new Date(article.publishedOn as string),
        "dddd DD MMMM"
      ),
    }),
    cancel: true,
    persistent: true,
  }).onOk(async () => {
    try {
      const date = article.publishedOn as string;
      await longFormatService.moveArticle(
        article.publication,
        article.id,
        date,
        date
      );
      loading.value = false;

      searchArticles();
    } catch (e) {
      console.error(e);
      $q.notify({
        type: "negative",
        message: formatErrorMessage(
          e,
          "exceptions.ArticleSaveException.SaveError"
        ),
      });
      loading.value = false;
    }
  });
}

/** Affichage de la popup permettant le changement de date de parution de l'article */
function handleShowDialogChangePublishedOn(article: ArticleDto) {
  let defaultValue = article.publishedOn ?? "";

  defaultValue = defaultValue.split("T")[0];

  dialogChangePublishedOnDate.value = defaultValue;

  dialogChangePublishedOnArticle.value = article;
  availableDates.value = formats.value
    .find((f) => f.format == article.contents[0].format)
    ?.dates.map((d) => d.split("T")[0].replaceAll("-", "/"));

  showDialogChangePublishedOn.value = true;
}
/** Retour valide de la popup permettant le changement de date */
async function handleDialogChangePublishedOnUpdate(newValue: {
  publishedOn: string;
  article: ArticleDto | undefined;
}) {
  if (!newValue.publishedOn || !newValue.article) return;

  const article = newValue.article;
  const date = newValue.publishedOn;
  loading.value = true;

  try {
    console.log("handleDialogChangePublishedOnUpdate", newValue, date);
    await longFormatService.moveArticle(
      article.publication,
      article.id,
      article.publishedOn as string,
      date
    );
    loading.value = false;

    searchArticles();
  } catch (e) {
    console.error(e);
    $q.notify({
      type: "negative",
      message: formatErrorMessage(
        e,
        "exceptions.ArticleSaveException.SaveError"
      ),
    });
    loading.value = false;
  }
}
/** Retour valide de la popup permettant le changement de date */
async function handleDialogAddSeries(newValue: {
  publication: string;
  format: string;
  startDate: string;
  endDate: string;
  articles: SeriesArticle[];
}) {
  if (!newValue.startDate || !newValue.endDate || !newValue.articles.length)
    return;

  console.log("handleDialogAddSeries", newValue);

  loading.value = true;

  try {
    await longFormatService.addSeries(
      newValue.publication,
      newValue.format,
      newValue.startDate,
      newValue.endDate,
      newValue.articles
    );
    loading.value = false;

    searchArticles();
  } catch (e) {
    console.error(e);
    $q.notify({
      type: "negative",
      message: formatErrorMessage(
        e,
        "exceptions.ArticleSaveException.SaveError"
      ),
    });
    loading.value = false;
  }
}
/** Affichage des paramètres de l'article */
function handleShowSettings(articleId: number) {
  actions.findArticle(articleId, false, false, () => {
    showPanelRight.value = true;
    currentArticleStore.$state.lang =
      currentArticleStore.$state.currentArticle.idMainLanguage;
  });
}

/** Construit le style de la card */
function getCardStyle(art: ArticleDto) {
  let style = "";

  if (getAuthorsName(art).length === 0) style += "background-color: #fa5555;";
  else style += getSectionCSSColors(getMainSection(art.sections)!.id, true);

  if (
    deadlineByAuthor.value.find((d) =>
      d.articlesInError.some((dl) => dl === art.id)
    )
  ) {
    style += "border: 4px dotted red";
  } else if (isArticleDoubleAtDate(art)) {
    const color = Dark.isActive ? "white" : "black";
    style += `border: 4px dashed ${color}`;
  }
  return style;
}

/** Informe si un article est en double à une date */
function isArticleDoubleAtDate(article: ArticleDto) {
  const format = article.contents[0].format;
  const date = article.publishedOn?.split("T")[0] ?? "";
  return computedArticlesSameDateError.value.some(
    (e) => e.format === format && e.dates.includes(date)
  );
}

/** Verifie la présence d'article trop proche pour lever une erreur */
function checkErrorOnDeadline(
  param: {
    deadlines: {
      id: number;
      date: number;
    }[];
    articlesInError: number[];
  },
  delayToDetectError: number
) {
  for (let i = 1; i < param.deadlines.length; i++) {
    if (
      param.deadlines[i].date - param.deadlines[i - 1].date <
      delayToDetectError * 24 * 60 * 60 * 1000
    ) {
      param.articlesInError.push(param.deadlines[i - 1].id);
      param.articlesInError.push(param.deadlines[i].id);
    }
  }
}

/** Retourne le nom de l'utilisateur à partir de son ID */
function getUserName(userId: string) {
  try {
    return findUserLight(userId).name;
  } catch (e) {
    console.error("Utilisateur non trouvé", e);
  }
  return i18n.t("user.deleted");
}

/** Retourne les doublons d'une liste */
function getUniqueDuplicates(array) {
  const counts = {};
  array.forEach((item) => {
    counts[item] = (counts[item] || 0) + 1;
  });
  return [...new Set(array.filter((item) => counts[item] > 1))];
}
</script>
<style scoped lang="scss">
.body--dark {
  .sample-error-same-date {
    border-color: white;
  }
}
.sample-error-deadline-author {
  border: 4px dotted red;
  display: inline-block;
  padding: 2px 5px;
}
.sample-error-same-date {
  border: 4px dashed black;
  display: inline-block;
  padding: 2px 5px;
}
.table-sticky-header {
  max-height: calc(100vh - 180px);
  tr th {
    position: sticky;
    z-index: 2;
  }
  thead tr:last-child th {
    top: 48px;
    z-index: 3;
  }

  thead tr:first-child th {
    top: 0;
    z-index: 1;
    height: 100%;
    &:not(:first-child) {
      div:first-child {
        text-align: center;
        padding-left: 16px;
      }
    }
  }
  tr:first-child th:first-child {
    z-index: 3;
    text-align: left;
  }
  td:first-child {
    z-index: 1;
  }
}
.card-selected {
  border: var(--q-primary) solid 4px;
}
.card-selected div.ellipsis {
  font-weight: bold;
}
.badge-state {
  height: 20px;
  width: 20px;
}
.badge-translate-margin {
  margin-right: 22px;
}
.img-flag {
  width: 15px;
  height: 15px;
}
</style>
