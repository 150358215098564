<template>
  <div v-if="language === 'all'">
    <div v-if="formatFr">
      {{ formatFr }}
    </div>
    <div v-if="formatEn">
      {{ formatEn }}
    </div>
  </div>
  <div v-else>
    <div v-if="formats[language]">
      {{ formats[language] }}
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs, computed } from "vue";
import { FrenchLanguage, EnglishLanguage } from "@/constants";
import { getLabelFormatByLanguage } from "@/helpers/article";

const props = defineProps<{
  data: ArticleODataDto;
  language: string;
  otherLanguage: string;
}>();
const { data } = toRefs(props);

const contentFr = computed(() => {
  return data.value.contents.find((c) => c.language === FrenchLanguage);
});
const contentEn = computed(() => {
  return data.value.contents.find((c) => c.language === EnglishLanguage);
});
const formatFr = computed(() => {
  if (!contentFr.value) return null;
  return getLabelFormatByLanguage(contentFr.value, FrenchLanguage);
});
const formatEn = computed(() => {
  if (!contentEn.value) return null;
  return getLabelFormatByLanguage(contentEn.value, EnglishLanguage);
});
const formats = computed(() => {
  return {
    [FrenchLanguage]: formatFr.value,
    [EnglishLanguage]: formatEn.value,
  };
});
</script>
