<template>
  <div>
    <q-tabs
      v-model="tabLang"
      align="left"
      active-color="primary"
      indicator-color="primary"
      narrow-indicator
      :breakpoint="0"
    >
      <q-tab
        v-for="(articleContent, indexArticleContent) in articleForm.contents"
        :key="'tab' + indexArticleContent"
        :label="$t(`languages.${articleContent.language}`)"
        :name="articleContent.language"
      />
    </q-tabs>
    <q-separator class="q-mt-none" />
    <q-tab-panels v-model="tabLang" animated>
      <q-tab-panel
        v-for="(articleContent, indexArticleContent) in articleForm.contents"
        :key="'tabPanel' + indexArticleContent"
        :name="articleContent.language"
        class="q-px-none q-px-md"
      >
        <div class="q-gutter-md">
          <CustomMultiSelect
            v-model="articleContent.settings"
            :disable="!!languagesLocked[articleContent.language]"
            :options="artSettingsCMSOptions"
            label="Paramètres CMS"
          />
          <p v-if="showCmsWarnMessage(articleContent)">
            {{ $t("article.labels.cmsWarnModifications") }}
          </p>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { getArticleSettingsOptions } from "@/helpers/article";
import { ArticleContentDto, SettingsType, WorkflowState } from "@/types/api";
import CustomMultiSelect from "@/components/Custom/CustomMultiSelect.vue";

const {
  currentArticle: articleForm,
  lang: tabLang,
  languagesLocked,
} = storeToRefs(useCurrentArticleStore());
const artSettingsCMSOptions = getArticleSettingsOptions(
  SettingsType.CMS,
  false
);

/** La traduction est elle importée */
function showCmsWarnMessage(content: ArticleContentDto) {
  if (content.language !== articleForm.value.idMainLanguage) return false;

  const translation = articleForm.value.contents.find(
    (c) => c.language !== content.language
  );
  return translation?.state === WorkflowState.Importe;
}
</script>
