import type { EventBus } from "quasar";
import type { Router } from "vue-router";
import type { ArticleViewActions } from "@/types/article";
import type { ArticleDto, ArticleSettingDto } from "@/types/api";
import type { Ref } from "vue";
import { toRefs, reactive, inject } from "vue";
import { useRouter } from "vue-router";
import { useQuasar, date, QVueGlobals } from "quasar";
import { articleService } from "@/services/ArticleService";
import { RoutesName } from "@/router/routesName";
import { usePage } from "@/composables/usePage";
import { useDraftStore } from "@/stores/draft";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { formatErrorMessage } from "@/utils";
import { i18n } from "@/i18n";
import { EnglishLanguage, FrenchLanguage } from "@/constants";
import DialogSaveArticle from "@/components/Article/DialogSaveArticle.vue";
import DialogLoadingDraftOrArticle from "@/components/Article/DialogLoadingDraftOrArticle.vue";
import DialogAutoInterests from "@/components/ArticleSetting/DialogAutoInterests.vue";

/**
 * Interface des états pour les actions article
 */
interface ArticleActionState {
  loading: boolean;
}

const state = reactive<ArticleActionState>({
  loading: false,
});

/**
 * Fonction servant à interagir sur les différentes vues article
 */
export function useArticleActions() {
  const $q = useQuasar();
  const bus = inject<EventBus>("bus");
  const router = useRouter();
  const draftStore = useDraftStore();
  const currentArticleStore = useCurrentArticleStore();
  const { showPanelRight } = usePage();

  const failedOnSaveArticle = initFailedOnSaveArticle($q);
  const showDialogAfterCreateArticle = initShowDialogAfterCreateArticle(
    showPanelRight,
    failedOnSaveArticle,
    $q,
    router,
    bus
  );
  const showDialogConfirmDeleteArticle = initShowDialogConfirmDeleteArticle(
    showPanelRight,
    failedOnSaveArticle,
    $q,
    router,
    bus
  );
  const showDialogErrorLoadingArticleWithDraft =
    initShowDialogErrorLoadingArticleWithDraft(state, $q, router);
  const showDialogArticleToSelect = initShowDialogArticleToSelect($q, router);

  const articleActions: ArticleViewActions = {
    async initArticle(
      articleDefault?: ArticleDto,
      history?: boolean,
      draft?: boolean
    ) {
      await currentArticleStore.initArticle(articleDefault, history, draft);
    },
    loadArticle(
      articleId: number,
      revisionId: string | undefined,
      preserveLocks = true
    ) {
      if (!revisionId) {
        this.findArticle(articleId, true, preserveLocks);
        return;
      }

      if (revisionId === "draft" || revisionId === "draftDeleted") {
        const draft = draftStore.findDraft(
          articleId,
          revisionId === "draftDeleted"
        );

        if (!draft) {
          router.push({
            name: RoutesName.EditArticle,
            params: { id: articleId },
          });
        } else {
          this.initArticle(draft, true, true);
        }
      } else {
        this.findArticleHistory(articleId, revisionId);
      }
    },
    findArticle(
      articleId: number,
      askForDraft: boolean,
      preserveLock: boolean,
      callback?: (article: ArticleDto) => void
    ) {
      state.loading = true;
      const draft = draftStore.findDraft(articleId, false);
      articleService
        .getSingle(articleId)
        .then((art) => {
          currentArticleStore.initArticle(art, false, false, preserveLock);
          if (!draft || !askForDraft) {
            if (callback) callback(art);
            return;
          }
          showDialogArticleToSelect(draft, art, callback);
        })
        .catch((err: Error) => {
          if (!draft) {
            $q.notify({ type: "negative", message: err.message });
            router.push({ name: RoutesName.Articles });
            return;
          }
          showDialogErrorLoadingArticleWithDraft(draft, err);
        })
        .finally(() => (state.loading = false));
    },
    findArticleHistory(
      articleId: number,
      revisionId: string,
      callback?: (article: ArticleDto) => void
    ) {
      state.loading = true;
      articleService
        .history(articleId, revisionId)
        .then((art) => {
          currentArticleStore.initArticle(art, true);
          if (callback) callback(art);
        })
        .catch((err: Error) => {
          $q.notify({ type: "negative", message: err.message });
          router.push({ name: RoutesName.Articles });
        })
        .finally(() => (state.loading = false));
    },
    async createArticle() {
      await currentArticleStore.initArticle();
      showPanelRight.value = true;
    },
    openNewTabEditArticle(
      articleId: number,
      lang?: string,
      draft?: boolean,
      draftDeleted?: boolean
    ) {
      currentArticleStore.initArticle();
      const query = lang ? { lang: lang } : {};
      if (draft) query["revisionId"] = "draft";
      if (draftDeleted) query["revisionId"] = "draftDeleted";

      const routeData = router.resolve({
        name: RoutesName.EditArticle,
        params: { id: articleId },
        query,
      });
      window.open(routeData.href, "_blank");
    },
    editArticle(
      articleId: number,
      lang?: string,
      draft?: boolean,
      draftDeleted?: boolean
    ) {
      currentArticleStore.initArticle();
      const query = lang ? { lang: lang } : {};
      if (draft) query["revisionId"] = "draft";
      if (draftDeleted) query["revisionId"] = "draftDeleted";

      router.push({
        name: RoutesName.EditArticle,
        params: { id: articleId },
        query,
      });
    },
    async getInterestsFromText(
      articleId: number,
      articleInterests: ArticleSettingDto[],
      dialogHandler: (articles: ArticleSettingDto[]) => void
    ): Promise<ArticleSettingDto[]> {
      try {
        const interests = await articleService.getInterestsFromText(articleId);

        const interestsFiltered = interests
          .filter((p) => articleInterests.findIndex((aa) => aa.id == p.id) < 0)
          .sort();

        $q.dialog({
          component: DialogAutoInterests,
          componentProps: {
            interests: interestsFiltered,
          },
        }).onOk((interestsReturned) => {
          dialogHandler(interestsReturned);
        });

        return [];
      } catch (e) {
        $q.notify(i18n.t("articleSetting.errors.getInterestFromText"));
        return [];
      }
    },
    onCreateArticle(article, callback) {
      showDialogAfterCreateArticle(article, callback);
    },
    onEditArticle(article, deleteDraft, callback) {
      const updateSavingState =
        currentArticleStore.currentArticle.id === article.id;
      if (updateSavingState) currentArticleStore.saving = true;

      articleService
        .update(article)
        .then(async (updatedArticle) => {
          showPanelRight.value = false;
          $q.notify(i18n.t("article.messages.updated"));
          await currentArticleStore.initArticle(
            updatedArticle,
            false,
            false,
            currentArticleStore.preserveLocks
          );

          if (deleteDraft) draftStore.deleteDraft(updatedArticle.id, false);
          if (callback) callback(updatedArticle);
          if (bus) bus.emit("refresh");
        })
        .catch((e: unknown) => {
          if (updateSavingState) currentArticleStore.saving = false;

          failedOnSaveArticle(article, e);
        });
    },
    onDeleteArticle(articleId: number) {
      showDialogConfirmDeleteArticle(articleId);
    },
    updatePublishedOn(
      articleId: number,
      mainLanguage: boolean,
      publishedOn: Date
    ) {
      return articleService.updatePublishedOn(
        articleId,
        mainLanguage,
        publishedOn
      );
    },
  };

  return { ...toRefs(state), actions: articleActions };
}

/**
 * Fonction pour sauvegarder l'article comme brouillon suite à l'echec de l'envoie côté serveur
 */
function initFailedOnSaveArticle<T>($q: QVueGlobals) {
  return (article: ArticleDto | undefined, e: T) => {
    const message = formatErrorMessage(e, "article.errors.onSave");
    if (article !== undefined) {
      const { putDraft } = useDraftStore();
      article.lastUpdate = new Date().toISOString();
      putDraft(article);
    }

    $q.notify({
      type: "warning",
      message,
    });
  };
}
/**
 * Fonction pour afficher message de confirmation pour supprimer l'article
 */
function initShowDialogConfirmDeleteArticle(
  showPanelRight: Ref<boolean>,
  failedOnSaveArticle: (article: ArticleDto | undefined, e: unknown) => void,
  $q: QVueGlobals,
  router: Router,
  bus: EventBus | undefined
) {
  return (articleId: string | number) => {
    $q.dialog({
      title: i18n.t("article.dialogs.titles.confirm"),
      message: i18n.t("article.dialogs.messages.delete"),
      cancel: true,
      persistent: true,
    }).onOk(() => {
      articleService
        .delete(articleId)
        .then(() => {
          showPanelRight.value = false;
          $q.notify(i18n.t("article.messages.deleted"));
          router.push({ name: RoutesName.Articles });
          if (bus) bus.emit("refresh");
        })
        .catch((e: unknown) => {
          failedOnSaveArticle(undefined, e);
        });
    });
  };
}
/**
 * Fonction pour afficher message avec actions possibles après création d'un article
 */
function initShowDialogAfterCreateArticle(
  showPanelRight: Ref<boolean>,
  failedOnSaveArticle: (article: ArticleDto | undefined, e: unknown) => void,
  $q: QVueGlobals,
  router: Router,
  bus: EventBus | undefined
) {
  let n = 0;

  return (article: ArticleDto, callback?: (art: ArticleDto) => void) => {
    $q.dialog({
      component: DialogSaveArticle,
      componentProps: {
        title: i18n.t("article.dialogs.titles.writeNow"),
      },
    })
      .onOk((payload: RoutesName) => {
        const params: { id?: number; refresh?: number } = {};
        if (payload === RoutesName.CreateArticle) {
          params.refresh = n++;
        }
        articleService
          .insert(article)
          .then((createdArticle) => {
            params.id = createdArticle.id;

            $q.notify(i18n.t("article.messages.created"));
            if (payload === RoutesName.CreateArticle && callback)
              callback(createdArticle);
            else {
              showPanelRight.value = false;
              router.push({
                name: payload,
                params,
              });
            }
            if (bus) bus.emit("refresh");
          })
          .catch((e: unknown) => {
            failedOnSaveArticle(article, e);
          });
      })
      .onCancel(() =>
        router.push({
          name: RoutesName.Articles,
        })
      );
  };
}

/**
 * Fonction pour afficher message qui laisse choisir d'afficher le brouillon ou l'article sauvegardé.
 */
function initShowDialogArticleToSelect($q: QVueGlobals, router: Router) {
  const currentArticleStore = useCurrentArticleStore();
  return (
    draft: ArticleDto,
    article: ArticleDto,
    callback?: (art: ArticleDto) => void
  ) => {
    const editableDraft = checkIfDraftCanBeEdit(draft, article);
    const message =
      editableDraft !== undefined
        ? "loadingDraftOrArticle"
        : "loadingNoEditableDraftOrArticle";
    const mask = "DD/MM/YYYY HH:mm";
    const draftDate = date.formatDate(draft.lastUpdate, mask);
    const articleLastUpdates = [
      article.lastUpdate,
      ...article.contents.map((c) => c.lastUpdate),
    ].sort((a, b) => b.localeCompare(a));
    const articleDate = date.formatDate(articleLastUpdates[0], mask);

    $q.dialog({
      component: DialogLoadingDraftOrArticle,
      componentProps: {
        draftId: draft.id,
        editableDraft: editableDraft,
        title: i18n.t(`article.dialogs.titles.${message}`),
        message: i18n.t(`article.dialogs.messages.${message}`, {
          draftDate,
          articleDate,
        }),
      },
    })
      .onOk(() => {
        if (editableDraft !== undefined) {
          currentArticleStore.initArticle(editableDraft, false, true);
          if (callback) callback(editableDraft);
        } else {
          router.push({
            name: RoutesName.EditArticle,
            params: { id: draft.id },
            query: { revisionId: "draft" },
          });
        }
      })
      .onCancel(() => {
        if (callback) callback(article);
      });
  };
}
/**
 * Vérifie si l'on peut éditer le brouillon sinon il faut l'ouvrir comme un historique.
 */
function checkIfDraftCanBeEdit(draft: ArticleDto, article: ArticleDto) {
  const draftInfo = {
    1: { xmin: draft.xmin },
    2: draft.contents.find((c) => c.language == FrenchLanguage),
    4: draft.contents.find((c) => c.language == EnglishLanguage),
  };
  const articleInfo = {
    1: { xmin: article.xmin },
    2: article.contents.find((c) => c.language == FrenchLanguage),
    4: article.contents.find((c) => c.language == EnglishLanguage),
  };
  const result =
    (draft.modificationType & 1) === 1 ? { ...draft } : { ...article };
  result.contents = [];
  result.modificationType = draft.modificationType;

  for (const k in draftInfo) {
    const i = parseInt(k);

    if ((draft.modificationType & i) === i) {
      if (draftInfo[i].xmin < articleInfo[i].xmin) return undefined;
      if (i > 1 && draftInfo[i]) result.contents.push(draftInfo[i]);
    } else if (i > 1 && draftInfo[i]) {
      result.contents.push(articleInfo[i]);
    }
  }

  return result;
}
/**
 * Fonction pour affiché message qui laisse choisir d'afficher le brouillon lorsqu'il y a une erreur à l'appel côté serveur.
 */
function initShowDialogErrorLoadingArticleWithDraft(
  state: ArticleActionState,
  $q: QVueGlobals,
  router: Router
) {
  const currentArticleStore = useCurrentArticleStore();
  return (draft: ArticleDto, err: Error) => {
    $q.dialog({
      title: i18n.t("article.dialogs.titles.loadingDraft"),
      message: i18n.t("article.dialogs.messages.loadingDraft"),
      cancel: true,
      persistent: true,
    })
      .onOk(() => currentArticleStore.initArticle(draft))
      .onCancel(() => {
        $q.notify({ type: "negative", message: err.message });
        router.push({ name: RoutesName.Articles });
      });
  };
}
