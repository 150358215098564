<template>
  <div class="q-gutter-xs">
    <q-btn
      round
      color="primary"
      size="sm"
      icon="edit"
      @click="actions.openNewTabEditArticle(article.id, language)"
    >
      <q-tooltip class="bg-primary">{{
        $t("article.labels.btnEdit")
      }}</q-tooltip>
    </q-btn>
    <q-btn
      round
      color="secondary"
      size="sm"
      icon="settings"
      @click="handleShowSettings(article.id)"
    >
      <q-tooltip class="bg-primary">{{
        $t("article.labels.btnSettings")
      }}</q-tooltip>
    </q-btn>
    <q-btn
      v-if="getLinkArticleWebsite(article.contents, language, true)"
      round
      outline
      size="sm"
      icon="open_in_browser"
      @click="handleOpenArticleWebsite(article)"
    >
      <q-tooltip class="bg-primary">{{
        $t("search.action.openWebsiteLinkArticleTooltip")
      }}</q-tooltip>
    </q-btn>
    <q-btn
      v-if="
        getLinkArticleWebsite(article.contents, language, true) === undefined
      "
      round
      outline
      size="sm"
      icon="visibility"
      @click="handlePreview(article.id, language)"
    >
      <q-tooltip class="bg-primary">{{
        $t("article.labels.btnPreview")
      }}</q-tooltip>
    </q-btn>
    <q-btn
      round
      outline
      size="sm"
      icon="print"
      @click="handlePrintArticle(article.id)"
    >
      <q-tooltip class="bg-primary">{{
        $t("search.action.printArticle")
      }}</q-tooltip>
    </q-btn>

    <q-btn
      v-if="article.contents.length === 1 && article.idPublication != 'LLA'"
      round
      outline
      size="sm"
      icon="translate"
      @click="handleCreateTranslation(article.id)"
    >
      <q-tooltip class="bg-primary">{{
        $t("search.action.createTranslation")
      }}</q-tooltip>
    </q-btn>
  </div>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs } from "vue";
import { useRouter } from "vue-router";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useArticleActions } from "@/composables/useArticleActions";
import { usePage } from "@/composables/usePage";
import { getLinkArticleWebsite } from "@/helpers/article";
import { RoutesName } from "@/router/routesName";
import { articleService } from "@/services/ArticleService";

const props = defineProps<{
  article: ArticleODataDto;
  language: string;
}>();
const { language } = toRefs(props);
const emits = defineEmits<{
  (e: "createTranslation"): void;
  (e: "openingSettings"): void;
}>();
const { actions } = useArticleActions();
const currentArticleStore = useCurrentArticleStore();
const { showPanelRight } = usePage();
const router = useRouter();

/**
 *
 */
function handleShowSettings(articleId: number) {
  emits("openingSettings");
  actions.findArticle(articleId, false, false, () => {
    showPanelRight.value = true;
    currentArticleStore.$state.lang = language.value;
  });
}
/**
 *
 */
function handleOpenArticleWebsite(article: ArticleODataDto) {
  const url = getLinkArticleWebsite(article.contents, language.value, true);
  if (url != null) window.open(url, "_blank");
}
/**
 *
 */
function handlePrintArticle(articleId: number) {
  const routeData = router.resolve({
    name: RoutesName.Preview,
    params: { articleIds: articleId },
    query: { lang: language.value },
  });

  window.open(routeData.href, "_blank");
}
/**
 * Demande d'ouvrir l'article en preview
 */
async function handlePreview(articleId: number, lang: string) {
  await articleService.createPreview(articleId, lang);
}
/**
 *
 */
function handleCreateTranslation(articleId: number) {
  actions.findArticle(articleId, false, false, () => {
    emits("createTranslation");
  });
}
</script>
