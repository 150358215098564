<template>
  <q-card bordered>
    <q-card-section
      :class="{
        sticky: $q.screen.gt.xs,
        'bg-white': !Dark.isActive,
        'bg-dark': Dark.isActive,
      }"
    >
      <div class="row wrap justify-start items-start content-start q-gutter-sm">
        <q-btn-dropdown
          v-if="!isFeuilleton"
          color="primary"
          :label="nomVueCourante"
          menu-anchor="bottom right"
        >
          <q-list style="min-width: 250px">
            <!--On click set the value of period to false and fridge to false-->
            <q-item v-close-popup clickable @click="setCurrentView('daily')">
              <q-item-section>
                <q-item-label>
                  {{ $t("article.labels.btnModeDaily") }}
                </q-item-label>
              </q-item-section>
              <q-tooltip :delay="200" anchor="center right" self="center left">
                {{ $t("article.labels.btnModeDailyTooltip") }}
              </q-tooltip>
            </q-item>
            <q-item v-close-popup clickable @click="setCurrentView('period')">
              <q-item-section>
                <q-item-label>
                  {{ $t("article.labels.btnModeDates") }}
                </q-item-label>
              </q-item-section>
              <q-tooltip :delay="200" anchor="center right" self="center left">
                {{ $t("article.labels.btnModeDatesTooltip") }}
              </q-tooltip>
            </q-item>
            <q-separator />
            <q-item v-close-popup clickable @click="setCurrentView('fridge')">
              <q-item-section>
                <q-item-label>
                  {{ $t("article.labels.btnModeFride") }}
                </q-item-label>
              </q-item-section>
              <q-tooltip :delay="200" anchor="center right" self="center left">
                {{ $t("article.labels.btnModeFrideTooltip") }}
              </q-tooltip>
            </q-item>
            <q-separator />
            <q-item>
              <q-item-section>
                <q-input
                  v-model.number="inputIdElcano"
                  type="number"
                  filled
                  dense
                  :placeholder="$t('article.fields.idWorkflowOrContent')"
                  @keyup.enter="updateFilter"
                >
                  <template #prepend>
                    <q-icon name="pin" />
                  </template>
                  <template #after>
                    <q-btn
                      flat
                      round
                      dense
                      icon="search"
                      @click="updateFilter"
                    />
                  </template>
                </q-input>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-input
                  v-model.number="inputIdBackOffice"
                  type="number"
                  filled
                  dense
                  :placeholder="$t('article.fields.idBackOffice')"
                  @keyup.enter="updateFilter"
                >
                  <template #prepend>
                    <q-icon name="tag" />
                  </template>
                  <template #after>
                    <q-btn
                      flat
                      round
                      dense
                      icon="search"
                      @click="updateFilter"
                    />
                  </template>
                </q-input>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <CustomResponsiveMenu
          :items="[
            'itemPublicationDate:notresponsive',
            'itemPublicationStart:notresponsive',
            'itemPublicationEnd:notresponsive',
            'itemPublicationFilter:responsive',
            'itemFormatFilter:responsive',
            'itemSectionFilter:responsive',
            'itemStateFilter:responsive',
            'itemContributionFilter:responsive',
            'itemLanguageFilter:responsive',
            'itemEditionLocked:responsive',
            'itemPdfPreview:responsive',
            'itemPrintArticles:responsive',
            'itemPreviewArticles:responsive',
            'itemAutoRefresh:responsive',
          ]"
          :label="$t('article.labels.btnSettings')"
          :icon="'settings'"
          color="secondary"
        >
          <template v-if="!period && !fridge" #itemPublicationDate>
            <InputDate
              v-model="startDate"
              type="date"
              :add-day="true"
              dense
              :label="$t('article.fields.publishedOn')"
            >
              <template #before>
                <q-btn
                  outline
                  round
                  icon="chevron_left"
                  @click="handlePreviousEdition"
                >
                  <q-tooltip :delay="200">
                    {{ $t("article.labels.btnPreviousEditionToolTip") }}
                  </q-tooltip>
                </q-btn>
              </template>
              <template #after>
                <q-btn
                  outline
                  round
                  icon="chevron_right"
                  @click="handleNextEdition"
                >
                  <q-tooltip :delay="200">
                    {{ $t("article.labels.btnNextEditionToolTip") }}
                  </q-tooltip>
                </q-btn>
              </template>
            </InputDate>
          </template>
          <template
            v-if="period && !fridge && !isFeuilleton"
            #itemPublicationStart
          >
            <InputDate
              v-model="startDate"
              dense
              type="date"
              :label="$t('article.fields.shortStartDate')"
            />
          </template>
          <template
            v-if="period && !fridge && !isFeuilleton"
            #itemPublicationEnd
          >
            <InputDate
              v-model="endDate"
              dense
              type="date"
              :label="$t('article.fields.shortEndDate')"
            />
          </template>
          <template v-if="period || fridge" #itemPublicationFilter>
            <q-select
              v-model="inputPublicationFilter"
              :options="getPublicationOptions({ allOption: false })"
              filled
              dense
              multiple
              emit-value
              map-options
              :label="$t('article.fields.publication')"
            />
          </template>
          <template v-if="period || fridge" #itemFormatFilter>
            <q-select
              v-model="inputFormatFilter"
              :options="
                getFormatOptions({
                  allOption: false,
                  publication: config.publication,
                })
              "
              filled
              dense
              emit-value
              map-options
              multiple
              :label="$t('article.fields.format')"
              style="min-width: 150px"
              @update:model-value="(format) => $emit('update:formats', format)"
            />
          </template>
          <template v-if="period || fridge" #itemStateFilter>
            <q-select
              v-model="inputStateFilter"
              :options="getWorflowState()"
              emit-value
              map-options
              filled
              dense
              style="min-width: 150px"
              :label="$t('article.fields.state')"
            />
          </template>
          <template v-if="period || fridge" #itemContributionFilter>
            <q-select
              v-model="inputContributorFilter"
              :options="contributorOptions"
              use-input
              emit-value
              map-options
              filled
              dense
              input-debounce="50"
              style="min-width: 150px"
              :label="
                fridge
                  ? $t('contributionType.Author')
                  : $t('articleContribution.fields.contributor')
              "
              @filter="filterInputContributor"
            />
          </template>
          <template #itemLanguageFilter>
            <q-btn-dropdown
              color="secondary"
              :label="$t(`languages.${language}`)"
            >
              <q-list>
                <q-item v-close-popup clickable @click="updateLanguage('all')">
                  <q-item-section>{{ $t("languages.all") }}</q-item-section>
                </q-item>
                <q-item
                  v-for="(lang, idx) in languages"
                  :key="'language' + idx"
                  v-close-popup
                  clickable
                  @click="updateLanguage(lang)"
                >
                  <q-item-section>
                    <q-item-label>{{ $t(`languages.${lang}`) }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </template>
          <template v-if="!period && !fridge" #itemEditionLocked>
            <q-btn
              :disable="editionLocked === null || !canLockEdition"
              :icon="lockEditionIcon"
              :label="
                $q.screen.lt.sm
                  ? editionLocked === null
                    ? $t('article.labels.btnLockIssueToolTip')
                    : editionLocked
                    ? $t('article.labels.btnUnlockIssueToolTip')
                    : $t('article.labels.btnLockIssueToolTip')
                  : undefined
              "
              @click="handleLockEdition"
            >
              <q-tooltip v-if="$q.screen.gt.xs" :delay="200">
                {{
                  editionLocked === null
                    ? $t("article.labels.btnLockIssueToolTip")
                    : editionLocked
                    ? $t("article.labels.btnUnlockIssueToolTip")
                    : $t("article.labels.btnLockIssueToolTip")
                }}
              </q-tooltip>
            </q-btn>
          </template>
          <template
            v-if="!period && !fridge && totalItemsCount > 1"
            #itemPrintArticles
          >
            <router-link
              :to="{
                name: RoutesName.Preview,
                params: {
                  articleIds:
                    articlesSource
                      .items()
                      .map((a) => a.id)
                      .join(',') || '0',
                },
                query: { lang: language },
              }"
              target="_blank"
            >
              <q-btn icon="print">
                <q-tooltip v-if="$q.screen.gt.xs" :delay="200">
                  {{ $t("article.labels.btnPrintArticlesTooltip") }}
                </q-tooltip>
              </q-btn>
            </router-link>
          </template>
          <template
            v-if="
              currentUserIsEditor && !period && !fridge && totalItemsCount >= 1
            "
            #itemPreviewArticles
          >
            <q-btn icon="visibility" @click="handlePreview">
              <q-tooltip v-if="$q.screen.gt.xs" :delay="200">
                {{ $t("article.labels.btnPreview") }}
              </q-tooltip>
            </q-btn>
          </template>
          <template v-if="!period && !fridge" #itemPdfPreview>
            <q-btn-dropdown icon="picture_as_pdf" :loading="loadingPdf">
              <q-list>
                <q-item v-close-popup clickable @click="generatePreviewPdf">
                  <q-item-section>
                    <q-item-label>{{
                      $t("article.labels.btnPreviewPDF")
                    }}</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item v-close-popup clickable @click="generatePdf">
                  <q-item-section>
                    <q-item-label>{{
                      $t("article.labels.btnDownloadPDF")
                    }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>

              <q-tooltip :delay="200">
                {{ $t("article.labels.btnGeneratePdfIssueToolTip") }}
              </q-tooltip>
            </q-btn-dropdown>
          </template>
          <template #itemAutoRefresh>
            <AutoRefresh
              :auto-refresh="true"
              :auto-refresh-time-in-seconds="60"
              :refresh-function="() => articlesSource.reload()"
              :refresh-condition-checker="() => showPanelRight == false"
              :auto-refresh-toggle="true"
            />
          </template>
          <template
            v-if="(period || fridge) && inputPublicationFilter.length == 1"
            #itemSectionFilter
          >
            <q-select
              v-model="inputSectionFilter"
              :options="
                getSectionOptions({
                  allOption: false,
                  publication: config.publication,
                })
              "
              filled
              dense
              emit-value
              map-options
              multiple
              :label="$t('article.fields.sections')"
              style="min-width: 150px"
            />
          </template>
        </CustomResponsiveMenu>
      </div>
    </q-card-section>
    <q-card-section v-if="displayTable" class="row q-pt-none">
      <div class="dx-item-top topDxTableInfo">
        <div v-if="!period && !fridge">
          <span v-if="$q.screen.gt.sm"
            >{{ $t("article.labels.editionOf") }}&nbsp;</span
          >
          <b>
            {{
              date.formatDate(startDate, isFr ? "dddd DD MMMM" : "dddd, MMMM D")
            }}
          </b>
        </div>
        <div v-if="getCurrentViewName() === 'daily'">
          <b>{{ totalItemsCount.toLocaleString() }}</b>
          {{ $t("article.labels.articleCount") }},
          <b>{{ totalWordCount.toLocaleString() }}</b>
          {{ $t("article.labels.wordCount") }},
          <b>{{ totalCharCount.toLocaleString() }}</b>
          {{ $t("article.labels.characterCount") }}
        </div>
        <div v-if="getCurrentViewName() !== 'daily'">
          <b>{{ totalItemsCount.toLocaleString() }}</b>
          {{ $t("article.labels.articleCount") }}
        </div>
      </div>
      <DxDataGrid
        ref="datagrid"
        class="dx-card wide-card"
        :date-serialization-format="'yyyy-MM-ddTHH:mm:ssZ'"
        :class="{ 'dx-swatch-dark': Dark.isActive }"
        :show-borders="true"
        :data-source="articlesSource"
        :allow-column-resizing="true"
        :allow-column-reordering="true"
        :row-alternation-enabled="true"
        column-resizing-mode="nextColumn"
        :column-hiding-enabled="$q.screen.lt.md"
        :columns="columns"
        :column-min-width="50"
        :column-auto-width="true"
        :column-chooser="{ search: { enabled: true }, enabled: true }"
        @selection-changed="onSelectionChanged"
      >
        <DxHeaderFilter :visible="true" />
        <DxSelection mode="single" />
        <DxGroupPanel :visible="false" />
        <DxGrouping :auto-expand-all="true" />
        <DxSearchPanel :visible="$q.screen.gt.xs" />
        <DxPaging :page-size="20" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[10, 20, 50]"
        />
        <DxStateStoring
          :enabled="true"
          type="custom"
          :storage-key="settingsStorageKey"
          :custom-load="loadState"
          :custom-save="saveState"
        />
        <DxScrolling column-rendering-mode="standard" />
        <template #cellFormatTemplate="{ data }">
          <CellFormatTemplate
            v-bind="{ data: data.value, language, otherLanguage }"
            class="cell-fill"
          />
        </template>
        <template #cellTitleTemplate="{ data }">
          <CellTitleTemplate
            v-bind="{
              data: data.value,
              language,
              otherLanguage,
              articleLocks,
              articleSerials,
            }"
            class="cell-fill"
          />
        </template>
        <template #cellStandfirstTemplate="{ data }">
          <CellStandfirstTemplate
            v-bind="{ data: data.value, language, otherLanguage }"
            class="cell-fill"
          />
        </template>
        <template #cellShortTitleTemplate="{ data }">
          <CellShortTitleTemplate
            v-bind="{ data: data.value, language, otherLanguage }"
            class="cell-fill"
          />
        </template>
        <template #cellWordSignCountTemplate="{ data }">
          <CellWordSignCountTemplate
            v-bind="{ data: data.value, language, otherLanguage }"
            class="cell-fill"
          />
        </template>
        <template #cellContributionsTemplate="{ data }">
          <CellContributionTemplate
            v-bind="{
              data: data.value,
              locks: articleLocks.filter((l) => l.articleId === data.value.id),
            }"
          />
        </template>
        <template #cellSectionsSecondariesTemplate="{ data }">
          <CellSectionsSecondariesTemplate v-bind="{ data: data.value }" />
        </template>
        <template #cellSectionsTemplate="{ data }">
          <CellSectionsTemplate v-bind="{ data: data.value }" />
        </template>
        <template #cellSectionsCountriesTemplate="{ data }">
          <CellSectionsCountriesTemplate v-bind="{ data: data.value }" />
        </template>
        <template #cellCountriesTemplate="{ data }">
          <CellCountriesTemplate v-bind="{ data: data.value }" />
        </template>
        <template #cellActionsTemplate="{ data }">
          <CellActionsTemplate
            v-bind="{ article: data.value, language, otherLanguage }"
            class="cell-fill"
            @create-translation="showDialogAddLanguage = true"
            @opening-settings="openingSettings = true"
          />
        </template>
        <template #cellStateTemplate="{ data }">
          <CellStateTemplate
            v-bind="{ data: data.value, language, otherLanguage }"
            class="cell-fill"
          />
        </template>
        <template #cellOnlineTemplate="{ data }">
          <CellOnlineTemplate
            v-bind="{ data: data.value, language, otherLanguage }"
            class="cell-fill"
          />
        </template>
      </DxDataGrid>
    </q-card-section>
    <DialogAddLanguage
      v-model="showDialogAddLanguage"
      :article="currentArticleStore.currentArticle"
      @add:language="onNewLanguage"
    />
  </q-card>
</template>

<script setup lang="ts">
import type { FilterDescriptor } from "devextreme/data";
import type { EventBus } from "quasar";
import { onMounted, onUnmounted, inject, ref, computed, watch } from "vue";
import { Dark, useQuasar, date } from "quasar";
import {
  DxDataGrid,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxSearchPanel,
  DxSelection,
  DxPaging,
  DxPager,
  DxStateStoring,
  DxScrolling,
} from "devextreme-vue/data-grid";
import { storeToRefs } from "pinia";
import {
  RightReference,
  ArticleLockDto,
  ContributionType,
  WorkflowState,
} from "@/types/api";
import { usePage } from "@/composables/usePage";
import { useListArticles } from "@/composables/useListArticles";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useConfigStore } from "@/stores/config";
import { useLanguageStore } from "@/stores/language";
import { getNextEditionDay, getPreviousEditionDay } from "@/helpers/article";
import { getPublicationOptions } from "@/helpers/publication";
import { getFormatOptions } from "@/helpers/format";
import { getSectionOptions } from "@/helpers/section";
import { getWorflowState } from "@/helpers/workflowState";
import { getContributorOptions } from "@/helpers/contributor";
import { articlesSourceFactory } from "@/services/dataSource";
import { authService } from "@/services/AuthService";
import { editionService } from "@/services/EditionService";
import { locale, loadMessages } from "devextreme/localization";
import frMessages from "devextreme/localization/messages/fr.json";
import enMessages from "devextreme/localization/messages/en.json";
import { i18n } from "@/i18n";
import {
  getDateWithNbDays,
  formatDate,
  useLocale,
  removeDiacritics,
} from "@/utils";
import InputDate from "@/components/Custom/CustomInputDate.vue";
import CustomResponsiveMenu from "@/components/Custom/CustomResponsiveMenu.vue";
import AutoRefresh from "@/components/Custom/AutoRefresh.vue";
import CellFormatTemplate from "@/components/Article/ListArticles/CellFormatTemplate.vue";
import CellTitleTemplate from "@/components/Article/ListArticles/CellTitleTemplate.vue";
import CellStandfirstTemplate from "@/components/Article/ListArticles/CellStandfirstTemplate.vue";
import CellShortTitleTemplate from "@/components/Article/ListArticles/CellShortTitleTemplate.vue";
import CellWordSignCountTemplate from "@/components/Article/ListArticles/CellWordSignCountTemplate.vue";
import CellContributionTemplate from "@/components/Article/ListArticles/CellContributionTemplate.vue";
import CellSectionsSecondariesTemplate from "@/components/Article/ListArticles/CellSectionsSecondariesTemplate.vue";
import CellSectionsTemplate from "@/components/Article/ListArticles/CellSectionsTemplate.vue";
import CellSectionsCountriesTemplate from "@/components/Article/ListArticles/CellSectionsCountriesTemplate.vue";
import CellCountriesTemplate from "@/components/Article/ListArticles/CellCountriesTemplate.vue";
import CellActionsTemplate from "@/components/Article/ListArticles/CellActionsTemplate.vue";
import CellStateTemplate from "@/components/Article/ListArticles/CellStateTemplate.vue";
import CellOnlineTemplate from "@/components/Article/ListArticles/CellOnlineTemplate.vue";
import DialogAddLanguage from "@/components/Article/DialogAddLanguage.vue";
import { FrenchLanguage, EnglishLanguage, ArticleFormat } from "@/constants";
import { lockService } from "@/services/LockService";
import { articleService } from "@/services/ArticleService";
import { useDebounceFn } from "@vueuse/core";
import { RoutesName } from "@/router/routesName";

/**
 * Type pour les différentes vues liste
 */
type ListView = "daily" | "fridge" | "period";

const props = withDefaults(
  defineProps<{
    listView?: string;
    startDate?: string;
    endDate?: string;
    formats?: string[];
    language?: string;
  }>(),
  {
    listView: "daily",
    startDate: "",
    endDate: "",
    formats: () => [],
    language: "",
  }
);
const emits = defineEmits<{
  (e: "update:formats", value: string): void;
  (e: "update:language", value: string): void;
  (e: "update:listView", value: Record<string, unknown>): void;
}>();
const { language: languageApp, isFr } = useLocale();
const currentArticleStore = useCurrentArticleStore();
const { config, keyGridArticleSettings } = storeToRefs(useConfigStore());

const bus = inject<EventBus>("bus");
const $q = useQuasar();
const {
  columns,
  datagrid,
  startDate,
  endDate,
  language,
  formats: inputFormatFilter,
  period,
  fridge,
} = useListArticles({
  fridge: props.listView === "fridge",
  period: props.listView === "period",
  startDate: props.startDate,
  endDate: props.endDate,
  language: props.language || languageApp.value,
  formats: props.formats,
});
const { showPanelRight } = usePage();
const { languages } = useLanguageStore();
const inputPublicationFilter = ref([config.value.publication]);
const lastKnownPublicationDaily = ref<string>();
const inputStateFilter = ref<string>("");
const inputIdBackOffice = ref<number>();
const inputIdElcano = ref<number>();
const inputContributorFilter = ref<string>();
const inputSectionFilter = ref<number[]>([]);
const showDialogAddLanguage = ref<boolean>(false);
const openingSettings = ref<boolean>(false);
const formatFilter = computed(() => {
  if (getCurrentViewName() === "daily")
    return [`contents/any(c: tolower(c/format) ne 'feuilleton')`];
  if (inputFormatFilter.value.length === 0) return undefined;
  if (inputFormatFilter.value.length === 1)
    return [
      `contents/any(c: tolower(c/format) eq '${inputFormatFilter.value[0].toLowerCase()}')`,
    ];
  return inputFormatFilter.value.reduce(
    (acc: (string | string[])[], f: string, idx: number) => {
      if (idx === 0) {
        acc.push([
          `contents/any(c: tolower(c/format) eq '${f.toLowerCase()}')`,
        ]);
      } else {
        acc.push("or");
        acc.push([
          `contents/any(c: tolower(c/format) eq '${f.toLowerCase()}')`,
        ]);
      }
      return acc;
    },
    []
  );
});
const otherLanguage = computed(() => {
  if (language.value === FrenchLanguage) return EnglishLanguage;
  if (language.value === EnglishLanguage) return FrenchLanguage;
  return "";
});
const languageFilter = computed(() => {
  if (language.value.toLocaleLowerCase() === "all") {
    return [
      [
        `contents/any(c: contains(tolower(c/language), '${FrenchLanguage.toLowerCase()}'))`,
      ],
      "or",
      [
        `contents/any(c: contains(tolower(c/language), '${EnglishLanguage.toLowerCase()}'))`,
      ],
    ];
  } else {
    return `contents/any(c: contains(tolower(c/language), '${language.value.toLowerCase()}'))`;
  }
});
const canLockEdition = authService.hasRight(
  RightReference.EDITION_LOCK_MANAGEMENT,
  config.value.publication
);
const editionLocked = ref<boolean | null>(null);
const displayTable = ref<boolean>(true);

const totalItemsCount = ref<number>(0);
const totalWordCount = ref<number>(0);
const totalCharCount = ref<number>(0);

const articleLocks = ref<ArticleLockDto[]>([]);
const articleSerials = ref<number[]>([]);

const lockEditionIcon = computed(() => {
  if (editionLocked.value === null) return "pending";
  return editionLocked.value ? "lock" : "lock_open";
});
const settingsStorageKey = computed(() => {
  return `${keyGridArticleSettings.value}-${
    fridge.value
      ? "fridge"
      : period.value
      ? formatFilter.value || "custom"
      : "daily"
  }`;
});

watch([settingsStorageKey], () => {
  displayTable.value = false;

  showTable();
});

const showTable = useDebounceFn(() => {
  displayTable.value = true;
}, 100);

const isFeuilleton = computed(() => {
  return (
    inputFormatFilter.value.length === 1 &&
    inputFormatFilter.value[0] === "Feuilleton"
  );
});

const nomVueCourante = computed(() => {
  if (period.value) {
    return i18n.t("article.labels.btnModeDates");
  }
  if (fridge.value) {
    return i18n.t("article.labels.btnModeFride");
  }
  return i18n.t("article.labels.btnModeDaily");
});
const currentUserIsEditor = computed(() => {
  return authService.isEditor(config.value.publication);
});

const loadingPdf = ref<boolean>(false);
const htmlContentPdfPreview = ref<string>("");
const contributorOptions = ref(
  getContributorOptions({
    type: ContributionType.Author,
    publication: config.value.publication,
  })
);

const articlesSource = articlesSourceFactory(getFilter());

loadMessages(config.value.locale === "fr" ? frMessages : enMessages);
locale(navigator.language);

articlesSource.on("loadingChanged", (isLoading) => {
  if (!isLoading) {
    calculateArticleMetrics();
    calculateLock();
    calculateSerials();
  }
});

onMounted(() => {
  articlesSource.load();
  getLockEdition();
  if (!bus) return;
  bus.on("resize", () => {
    setTimeout(() => {
      if (!datagrid.value) return;
      datagrid.value.instance?.repaint();
    }, 200);
  });
  bus.on("refresh", () => {
    if (!datagrid.value) return;
    datagrid.value.instance?.refresh();
  });
});

onUnmounted(() => {
  if (!bus) return;
  bus.off("resize");
  bus.off("refresh");
});

watch(
  () => props.listView,
  () => setCurrentView(props.listView as ListView)
);
watch(
  () => props.formats,
  () => {
    if (
      inputFormatFilter.value.length !== props.formats.length ||
      inputFormatFilter.value.some((f) => !props.formats.includes(f))
    ) {
      inputFormatFilter.value = props.formats;
    }
  }
);

watch(
  () => config.value.publication,
  () => {
    inputPublicationFilter.value = [config.value.publication];
    getLockEdition();
  }
);

watch(
  [
    fridge,
    startDate,
    endDate,
    language,
    inputFormatFilter,
    inputPublicationFilter,
    inputStateFilter,
    inputContributorFilter,
    inputSectionFilter,
  ],
  () => updateFilter()
);

watch([period, fridge, startDate, endDate], () => {
  let queries: Record<string, string | string[]> = {};
  const listView = getCurrentViewName();

  if (period.value) {
    editionLocked.value = null;
  } else {
    getLockEdition();
    endDate.value = getDateWithNbDays(new Date(startDate.value), 1);
  }
  if (listView === "fridge") queries = { listView };
  else {
    queries = {
      listView,
      startDate: formatDate(startDate.value),
      endDate: formatDate(endDate.value),
    };
    if (inputFormatFilter.value.length > 0)
      queries.formats = inputFormatFilter.value;
  }
  emits("update:listView", queries);
});

/** GetFilter */
function getFilter(): FilterDescriptor[] {
  const param: FilterDescriptor[] = [];

  setDefaultDateDailyView();

  if (inputIdBackOffice.value) {
    param.push([
      `contents/any(c: (c/idBackOffice eq ${inputIdBackOffice.value}))`,
    ]);
    return param;
  }

  if (inputIdElcano.value) {
    param.push([`contents/any(c: (c/idArticle eq ${inputIdElcano.value}))`]);
    param.push("or");
    param.push([`contents/any(c: (c/id eq ${inputIdElcano.value}))`]);
    return param;
  }

  param.push([languageFilter.value]);

  param.push("and");

  applyFilterOnDate(param);

  applyFilterOnPublications(param);

  // en vue quotidien on applique pas les filtres non visible
  if (getCurrentViewName() === "daily") return param;

  if (inputStateFilter.value) {
    param.push([`contents/any(c: (c/state eq '${inputStateFilter.value}'))`]);
    param.push("and");
  }

  if (inputContributorFilter.value) {
    param.push([
      `contributions/any(contributor: (contributor/idContributor eq '${inputContributorFilter.value}'))`,
    ]);
    param.push("and");
  }

  applyFilterOnSections(param);

  if (formatFilter.value && formatFilter.value.length > 0) {
    param.push(formatFilter.value);
  }

  return param;
}

/**
 * Applique le filtre sur les rubriques
 * @param param parametre
 */
function applyFilterOnSections(param: any[]) {
  const sections: FilterDescriptor[] = [];
  inputSectionFilter.value.forEach((p, idx) => {
    if (idx !== 0) sections.push("or");
    sections.push([`sections/any(c: (c/section eq ${p}))`]);
  });

  if (sections.length > 0) {
    param.push(sections);
    param.push("and");
  }
}

/**
 * Applique le filtre sur les publications
 * @param param parametre
 */
function applyFilterOnPublications(param: FilterDescriptor[]) {
  const publications: FilterDescriptor[] = [];

  if (getCurrentViewName() === "daily") {
    publications.push(["idPublication", "=", config.value.publication]);
  } else {
    inputPublicationFilter.value.forEach((p, idx) => {
      if (idx !== 0) publications.push("or");
      publications.push(["idPublication", "=", p]);
    });
  }

  if (publications.length > 0) {
    param.push(publications);
    param.push("and");
  }
}

/**
 * Applique le filtre sur les dates
 * @param param parametre
 */
function applyFilterOnDate(param: FilterDescriptor[]) {
  if (fridge.value) {
    param.push(["publishedOn", "=", null]);
  } else if (!isFeuilleton.value) {
    const startDateValue = startDate.value.replace("Z", "");
    const startDateWithoutTimezone = new Date(startDateValue);

    let endDateWithoutTimezone = new Date(startDateWithoutTimezone);
    if (getCurrentViewName() !== "daily") {
      const endDateValue = endDate.value.replace("Z", "");
      endDateWithoutTimezone = new Date(endDateValue);
    }

    endDateWithoutTimezone = new Date(
      endDateWithoutTimezone.setDate(endDateWithoutTimezone.getDate() + 1)
    );

    param.push([
      [
        ["idMainLanguage", "=", language.value],
        "and",
        [
          [
            ["publishedOn", ">=", startDateWithoutTimezone],
            "and",
            ["publishedOn", "<", endDateWithoutTimezone],
            "and",
            ["summaryOf", "=", null],
          ],
          "or",
          [
            ["summaryOf", ">=", startDateWithoutTimezone],
            "and",
            ["summaryOf", "<", endDateWithoutTimezone],
          ],
        ],
      ],
      "or",
      [
        ["idMainLanguage", "<>", language.value],
        "and",
        [
          [
            ["publishedOn", ">=", startDateWithoutTimezone],
            "and",
            ["publishedOn", "<", endDateWithoutTimezone],
            "and",
            ["summaryOf", "=", null],
            "and",
            ["publishedOnTranslation", "=", null],
            "and",
            ["summaryOfTranslation", "=", null],
          ],
          "or",
          [
            ["summaryOf", ">=", startDateWithoutTimezone],
            "and",
            ["summaryOf", "<", endDateWithoutTimezone],
            "and",
            ["summaryOf", "<>", null],
            "and",
            ["publishedOnTranslation", "=", null],
            "and",
            ["summaryOfTranslation", "=", null],
          ],
          "or",
          [
            ["publishedOnTranslation", ">=", startDateWithoutTimezone],
            "and",
            ["publishedOnTranslation", "<", endDateWithoutTimezone],
            "and",
            ["publishedOnTranslation", "<>", null],
            "and",
            ["summaryOfTranslation", "=", null],
          ],
          "or",
          [
            ["summaryOfTranslation", ">=", startDateWithoutTimezone],
            "and",
            ["summaryOfTranslation", "<", endDateWithoutTimezone],
            "and",
            ["summaryOfTranslation", "<>", null],
          ],
        ],
      ],
    ]);
  }
  param.push("and");
}

/** Update filter */
function updateFilter() {
  if (!datagrid.value) return;
  datagrid.value.instance?.filter(getFilter());
}

/** Click next edition */
function handleNextEdition() {
  const nextEdition = getNextEditionDay(new Date(startDate.value));
  startDate.value = nextEdition.toLocaleISOString();
}
/** Click previous edition */
function handlePreviousEdition() {
  const previousEdition = getPreviousEditionDay(new Date(startDate.value));
  startDate.value = previousEdition.toLocaleISOString();
}

/** Click sur le bouton preview */
function handlePreview() {
  const contents = articlesSource
    .items()
    .map((a) => (Array.isArray(a.contents) ? a : a.items))
    .flat()
    .map((a) => a.contents)
    .flat()
    .filter((c) => c.language == language.value);

  const openPreview = () => {
    contents.forEach((c) => {
      if (c.idBackOffice) {
        window.open(
          `${c.articleUrl ?? c.serialUrl}?ignorevalide=1&force=1&preview=1`,
          "_blank"
        );
      }
    });
  };

  const notImportedYet = contents.filter((c) => c.idBackOffice === null);
  if (notImportedYet.length > 0) {
    $q.notify({
      message: i18n.t("article.labels.notImportedYet", {
        titles: "<br>" + notImportedYet.map((c) => c.title).join("<br>"),
      }),
      progress: true,
      html: true,
      timeout: 20000,
      color: "primary",
      position: "top",
      icon: "report_problem",
      actions: [
        {
          label: i18n.t("article.labels.btnContinuePreview"),
          color: "white",
          handler: openPreview,
        },
      ],
    });
  } else {
    openPreview();
  }
}

/** Permet de changer la vue courante */
function setCurrentView(params: ListView) {
  fridge.value = params === "fridge";
  period.value = params === "period";

  updateFilter();
}

/** Change de langue */
function updateLanguage(lang: string) {
  language.value = lang;
  emits("update:language", lang);
}

/**
 * Permet de modifier les paramêtres de sélection de la vue quotidien par défaut
 * Ex sur Glitz on passe à l'édition suivante
 * Ex Le samedi on passe à l'édition suivante
 * Permet de gérer le switch de publication
 */
function setDefaultDateDailyView() {
  if (getCurrentViewName() !== "daily") return;

  // on vient de changer de publication
  if (
    lastKnownPublicationDaily.value &&
    inputPublicationFilter.value.length === 1 &&
    lastKnownPublicationDaily.value !== inputPublicationFilter.value[0]
  ) {
    const nextEdition = getNextEditionDay();
    startDate.value = nextEdition.toLocaleISOString();
    endDate.value = nextEdition.addDays(1).toLocaleISOString();
  }

  //On garde en mémoire la dernière publication pour gérer les changements de publications
  if (inputPublicationFilter.value.length === 1)
    lastKnownPublicationDaily.value = inputPublicationFilter.value[0];
}

/**
 * Recupère et set le lock de l'edition
 */
async function getLockEdition() {
  if (period.value) return;
  editionLocked.value = null;

  editionLocked.value = await editionService.isLocked(
    config.value.publication,
    new Date(startDate.value)
  );
}
/**
 * Génère la previsualisation de l'édition
 */
async function generatePreviewPdf() {
  loadingPdf.value = true;
  try {
    htmlContentPdfPreview.value = await editionService.getHtmlEdition(
      config.value.publication,
      language.value,
      new Date(startDate.value)
    );
  } catch (exp) {
    console.error(exp);
  }

  loadingPdf.value = false;
  if (htmlContentPdfPreview.value) {
    //Open a new popup with a html document
    const popup = window.open("", "_blank");
    if (popup) {
      popup.document.write(htmlContentPdfPreview.value);
      popup.document.close();
    }
  }
}
/**
 * Génère la previsualisation de l'édition
 */
async function generatePdf() {
  loadingPdf.value = true;
  try {
    await editionService.getPdfEdition(
      config.value.publication,
      language.value,
      new Date(startDate.value)
    );
  } catch (exp) {
    console.error(exp);
  }

  loadingPdf.value = false;
}

/**
 * Click sur le bouton de verrouillage de l'édition
 */
async function handleLockEdition() {
  if (editionLocked.value === null) return;
  const lockedState = editionLocked.value;
  editionLocked.value = null;
  let success = await editionService.setLock(
    config.value.publication,
    new Date(startDate.value),
    !lockedState
  );

  if (!success) editionLocked.value = lockedState;
  else editionLocked.value = !lockedState;
}

/**
 * Permet de renvoyer le nom/type de la vue courante
 */
function getCurrentViewName(): string {
  return fridge.value ? "fridge" : period.value ? "period" : "daily";
}

/**
 * Permet de calculer le nombre d'article, mots, signes de la liste article
 */
async function calculateArticleMetrics() {
  totalItemsCount.value = articlesSource.totalCount();

  if (getCurrentViewName() !== "daily") return;

  // Les articles sont groupés par jour, et on ne comptabilise que les articles du jour courant
  const articles = articlesSource
    .items()
    .map((a) => (Array.isArray(a.contents) ? a : a.items))
    .flat();

  const listContent = articles
    .map((a) => a.contents)
    .flat()
    .filter(
      (c) =>
        c.language == language.value &&
        c.format !== ArticleFormat.Feuilleton &&
        c.state !== WorkflowState.Invalide
    );

  totalItemsCount.value = listContent.length;

  totalWordCount.value = listContent
    .map((c) => c.wordCount)
    .reduce((partialSum, a) => partialSum + a, 0);

  totalCharCount.value = listContent
    .map((c) => c.charCount)
    .reduce((partialSum, a) => partialSum + a, 0);
}

/**
 * Permet de récupérer les verrous des articles de la liste
 */
async function calculateLock() {
  articleLocks.value = await lockService.getList(
    articlesSource
      .items()
      .map((a) => {
        return a.id ? a.id : a.items.map((i) => i.id);
      })
      .flat()
  );
}

/**
 * Permet de calculer le nombre d'article, mots, signes de la liste article
 */
async function calculateSerials() {
  articleSerials.value = await articleService.getLinkedToSerials(
    articlesSource
      .items()
      .map((a) => {
        return a.id ? a.id : a.items.map((i) => i.id);
      })
      .flat()
  );
}

/**
 * Fonction appelé lors du choix de la nouvelle langue à rajouter à l'article
 */
function onNewLanguage() {
  showDialogAddLanguage.value = true;
  articlesSource.reload();
}
/**
 * Fonction qui sert a filtrer dans les options contributeurs
 */
function filterInputContributor(val, update) {
  if (val === "") {
    update(() => {
      contributorOptions.value = getContributorOptions({
        type: fridge.value ? ContributionType.Author : undefined,
        publication: config.value.publication,
      });
    });
    return;
  }

  update(() => {
    const needle = removeDiacritics(val).toLowerCase();
    const options = getContributorOptions({
      type: fridge.value ? ContributionType.Author : undefined,
      publication: config.value.publication,
    });
    contributorOptions.value = options.filter(
      (v) => removeDiacritics(v.label).toLowerCase().indexOf(needle) > -1
    );
  });
}
/**
 * Fonction executé lors du changement de selection
 */
async function onSelectionChanged() {
  if (!showPanelRight.value) return;
  if (openingSettings.value) {
    openingSettings.value = false;
    return;
  }

  showPanelRight.value = false;
}
/**
 * Fonction executé lors du changement de selection
 */
function loadState() {
  return JSON.parse(localStorage.getItem(settingsStorageKey.value) || "{}");
}
/**
 * Fonction executé lors du changement de selection
 */
function saveState(state) {
  // on ne conserve pas la sélection, sinon la datagrid n'arrête pas de requêter la selection pour rien
  state.selectedRowKeys = [];
  localStorage.setItem(settingsStorageKey.value, JSON.stringify(state));
  return {};
}
</script>
<style scoped>
.sticky {
  position: sticky;
  top: 50px;
  z-index: 100;
}

:deep(.dx-datagrid) .dx-row > td:not(.dx-last-data-cell) {
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
}

:deep(.dx-datagrid-header-panel) {
  background-color: #f5f5f5 !important;
}
:deep(.dx-toolbar) {
  background-color: #f5f5f5 !important;
}
:deep(.dx-header-row) {
  background-color: #f5f5f5 !important;
}
:deep(.dx-master-detail-cell .dx-field-item-label) {
  font-weight: 800;
}
:deep(
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td
  ) {
  background-color: rgba(var(--q-primary-rgb), 0.18) !important;
  color: rgba(0, 0, 0, 0.87);
}

.img-flag {
  width: 20px;
  height: 20px;
  margin: auto 0px;
}

.dx-item-top {
  position: absolute;
  z-index: 10;
  left: 28px;
  top: 10px;
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
}

@media only screen and (max-width: 800px) {
  .dx-item-top {
    font-size: 0.7em;
  }
}

.dx-item-top > * + *::before {
  content: "•";
  margin-left: 8px;
  margin-right: 8px;
}

.cell-fill {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  height: 100%;
}
:deep(.cell-fill) > * {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-items: center;
  min-height: 50px;
}
:deep(.cell-fill) > *:not(:first-child) {
  margin-top: 9px;
}
</style>
