<template>
  <span v-if="refreshToggleVisible">
    <q-toggle
      v-model="autoRefreshModel"
      :color="autoRefresh ? 'primary' : 'negative'"
      :icon="autoRefresh ? 'autorenew' : 'lock_reset'"
      :label="
        $q.screen.lt.sm
          ? $t('common.labels.autoRefresh', {
              seconds: autoRefreshTimeInSeconds,
            })
          : undefined
      "
    />
    <q-tooltip v-if="$q.screen.gt.xs">
      {{
        $t("common.labels.autoRefresh", { seconds: autoRefreshTimeInSeconds })
      }}
    </q-tooltip>
  </span>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from "vue";
let lastClick = 0;

const props = defineProps({
  autoRefresh: {
    type: Boolean,
    required: true,
    default: true,
  },
  autoRefreshTimeInSeconds: {
    type: Number,
    required: true,
    default: 60,
  },
  refreshFunction: {
    type: Function,
    required: true,
  },
  refreshConditionChecker: {
    type: Function,
    required: true,
  },
  autoRefreshToggle: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const autoRefreshModel = ref<boolean>(props.autoRefresh);
const refreshToggleVisible = ref<boolean>(props.autoRefreshToggle);
const autoRefreshTimerInSeconds = ref<number>(props.autoRefreshTimeInSeconds);

let autoRefreshTimeout;

watch(
  () => props.autoRefresh,
  (newValue) => {
    autoRefreshModel.value = newValue;
  }
);

watch(autoRefreshModel, (newValue) => {
  const now = new Date().getTime();
  if (now - lastClick < 1000 && newValue) props.refreshFunction();
  lastClick = now;
});

watch(
  () => props.autoRefreshTimeInSeconds,
  (newValue) => {
    autoRefreshTimerInSeconds.value = newValue;
  }
);

/**
 *
 */
function initiateAutoRefresh(): void {
  if (autoRefreshModel.value) {
    autoRefreshTimeout = setTimeout(() => {
      if (autoRefreshModel.value && props.refreshConditionChecker()) {
        props.refreshFunction();
        initiateAutoRefresh();
      }
    }, autoRefreshTimerInSeconds.value * 1000);
  }
}

onMounted(() => {
  initiateAutoRefresh();
});

onUnmounted(() => {
  if (autoRefreshTimeout) clearTimeout(autoRefreshTimeout);
});
</script>
