import { WatchStopHandle, inject } from "vue";
import {
  ArticleDto,
  ArticleSectionDto,
  ArticleLockDto,
  ElcanoNotificationType,
  WorkflowState,
  ArticleVersionDto,
  ArticleContentDto,
  RightReference,
  PreviewResult,
} from "@/types/api";
import type { ArticleForm } from "@/types/article";
import { watch } from "vue";
import { defineStore } from "pinia";
import { useDraftStore } from "@/stores/draft";
import { articleService, createArticleForm } from "@/services/ArticleService";
import { signalRService } from "@/services/SignalrService";
import {
  getModificationTypeFromLanguage,
  modificationType,
} from "@/components/Article/constant";
import { EnglishLanguage, FrenchLanguage } from "@/constants";
import { authService } from "@/services/AuthService";
import { EventBus, Notify, Dialog, QNotifyUpdateOptions } from "quasar";
import { i18n } from "@/i18n";
import { useArticleSettingStore } from "@/stores/articleSetting";

const bus = inject<EventBus>("bus");

/**
 * Interface CurrentArticleState
 */
interface CurrentArticleState {
  currentArticle: ArticleForm;
  mainContent: string;
  translatedContent: string;
  modifExist: number;
  lockType: number;
  preserveLocks: boolean;
  locks: ArticleLockDto[];
  loading: boolean;
  saving: boolean;
  isHistory: boolean;
  isDraft: boolean;
  computingTextWithSuggestions: boolean;
  logAlert: boolean;
  lang: string | "fr-FR" | "en-EN";
  oldNumberOfLocks: number;
  versionBack: ArticleVersionDto;
}

let watchers: WatchStopHandle[] = [];
let lockTimer: ReturnType<typeof setInterval>;
let lockInterval: ReturnType<typeof setInterval>;
let saveDraftInterval: ReturnType<typeof setInterval>;
let guardRemover: (() => void) | null = null;
let statusUpdateTimeout: ReturnType<typeof setTimeout>;
const statusUpdateLanguage: string[] = [];
let notifyClose: ((props?: QNotifyUpdateOptions) => void) | null = null;

/**
 * Check si l'utilisateur a le droit de sauvegardé
 * @param state Etat de l'article
 * @param publication publication
 * @returns si l'utilisateur a le droit de sauvegardé
 */
function checkRightToSave(state: string, publication: string) {
  return authService.hasRight(
    `${RightReference.PUBLICATION_ARTICLE_STATE_SAVE_}${state}`,
    publication
  );
}

/**
 * Verifie si l'utilisateur a le droit d'édition sur toutes les langues.
 * @param article article
 */
function canModifyAllLanguages(article: ArticleForm) {
  return article.contents
    .map((c) => checkRightToSave(c.state, article.publication))
    .every((b) => b);
}

/**
 * Verifie si un des contenu est verrouillé et au statut importé.
 * Dans le but d'éviter des modifications qui ne pourront pas être réimporté automatiquement.
 * @param article article
 * @param locks verrous en cours sur l'article
 */
function oneContentIsImportedAndLocked(
  article: ArticleForm,
  locks: ArticleLockDto[]
) {
  return article.contents
    .map(
      (c) =>
        c.state == WorkflowState.Importe &&
        locks.some(
          (l) =>
            (l.modificationType & getModificationTypeFromLanguage(c.language)) >
              0 && l.userId != authService.getUserId()
        )
    )
    .some((b) => b);
}

/**
 * Notifie l'utilisateur qu'il y a eu une erreur lors de la prise de verrou
 */
function notifyTakeLockError() {
  Notify.create({
    message: i18n.t("article.errors.takeLock"),
    color: "negative",
    timeout: 15_000,
  });
}

export const useCurrentArticleStore = defineStore({
  id: "currentArticle",
  state: (): CurrentArticleState => ({
    currentArticle: createArticleForm(),
    mainContent: "",
    translatedContent: "",
    modifExist: modificationType.None,
    lockType: modificationType.None,
    preserveLocks: true,
    locks: [],
    loading: false,
    saving: false,
    lang: FrenchLanguage,
    isHistory: false,
    isDraft: false,
    computingTextWithSuggestions: false,
    logAlert: false,
    oldNumberOfLocks: 0,
    versionBack: {
      xmin: 0,
      lastModificationAuthor: "",
      lastModificationIdAuthor: "",
      contentsVersion: [],
    },
  }),
  getters: {
    sectionActives(state) {
      const list: ArticleSectionDto[] =
        state.currentArticle.sectionSecondaries.map((sec) => ({
          main: false,
          section: sec,
        }));
      if (state.currentArticle.sectionPrimary)
        list.push({
          main: true,
          section: state.currentArticle.sectionPrimary,
        });

      return list;
    },
    isVersionObsolete(state) {
      const versionObsolete = {};

      if (
        !state.versionBack.contentsVersion &&
        !state.versionBack.contentsVersion
      )
        return versionObsolete;

      versionObsolete["base"] =
        state.versionBack.xmin > 0 &&
        state.versionBack.xmin > state.currentArticle.xmin;

      const frenchBackVersion =
        state.versionBack.contentsVersion.find(
          (c) => c.language == FrenchLanguage
        )?.xmin ?? 0;

      const englishBackVersion =
        state.versionBack.contentsVersion.find(
          (c) => c.language == EnglishLanguage
        )?.xmin ?? 0;

      versionObsolete[FrenchLanguage] =
        state.versionBack.xmin > 0 &&
        frenchBackVersion >
          (state.currentArticle.contents.find(
            (c) => c.language == FrenchLanguage
          )?.xmin ?? 0);

      versionObsolete[EnglishLanguage] =
        state.versionBack.xmin > 0 &&
        englishBackVersion >
          (state.currentArticle.contents.find(
            (c) => c.language == EnglishLanguage
          )?.xmin ?? 0);

      return versionObsolete;
    },
    baseIsLocked(state) {
      if (state.isHistory) return true;
      if (state.currentArticle.deleted) return true;

      if (this.isVersionObsolete["base"]) return true;

      if (!canModifyAllLanguages(state.currentArticle)) return true;

      if (oneContentIsImportedAndLocked(state.currentArticle, state.locks))
        return true;

      return state.locks.some(
        (l) =>
          1 === (l.modificationType & 1) && l.userId != authService.getUserId()
      );
    },
    contributionsIsLocked(state) {
      if (state.isHistory) return true;
      if (state.currentArticle.deleted) return true;

      if (
        !authService.hasRight(
          RightReference.PUBLICATION_ARTICLE_STATE_SAVE_,
          state.currentArticle.publication
        )
      )
        return true;

      return state.locks.some(
        (l) =>
          1 === (l.modificationType & 1) && l.userId != authService.getUserId()
      );
    },
    languagesLocked(state) {
      const locked = {};

      const isTranslator = authService.isTranslatorOnly(
        state.currentArticle.publication
      );
      const isEditor = authService.isEditor(state.currentArticle.publication);

      const checkTranslator = function (content: ArticleContentDto) {
        if (!isTranslator) return false;

        if (content.language !== state.currentArticle.idMainLanguage)
          return false;

        return state.currentArticle.idMainLanguage === FrenchLanguage;
      };

      const checkReviewer = function (content: ArticleContentDto) {
        /// Si pas sur relecture on ne bloque pas
        if (content.state !== WorkflowState.Relecture) return false;

        //Editeur, et admin ont tous les droits
        if (isEditor) return false;

        const isRedactor = authService.isRedactor(
          state.currentArticle.publication
        );
        const isTranslator = authService.isTranslatorOnly(
          state.currentArticle.publication
        );

        // Suite à des modifications, les relectures ne peuvent être réalisés que par un rédacteur de la publication
        // Voir par un traducteur pour les traductions
        return state.currentArticle.idMainLanguage === content.language
          ? !isRedactor
          : !isRedactor && !isTranslator;
      };

      const isVersionObsolete = this.isVersionObsolete;

      state.currentArticle.contents.forEach((c) => {
        const i = getModificationTypeFromLanguage(c.language);
        locked[c.language] = undefined;

        if (state.currentArticle.deleted) locked[c.language] = "deleted";
        if (state.isHistory) locked[c.language] = "history";
        else if (isVersionObsolete[c.language]) locked[c.language] = "obsolete";
        else if (checkTranslator(c))
          locked[c.language] = "translatorCannotEditMainLanguage";
        else if (checkReviewer(c)) locked[c.language] = "reviewOnlyByRedactor";
        else if (!checkRightToSave(c.state, state.currentArticle.publication))
          locked[c.language] = "rightToSave";
        else {
          const lock = state.locks.find(
            (l) =>
              i === (l.modificationType & i) &&
              l.userId != authService.getUserId()
          );

          if (lock) locked[c.language] = `lockedByAnotherUser|${lock.userName}`;
        }
      });
      return locked;
    },
    getLastModificationAuthor(state) {
      return state.versionBack.lastModificationAuthor;
    },
    getLastModificationIdAuthor(state) {
      return state.versionBack.lastModificationIdAuthor;
    },
  },
  actions: {
    initContents() {
      const idMainLanguage = this.currentArticle.idMainLanguage;

      const mainContent = this.currentArticle.contents?.find(
        (c) => c.language === idMainLanguage
      );
      this.mainContent = JSON.stringify(mainContent);

      const translatedContent = this.currentArticle.contents?.find(
        (c) => c.language !== idMainLanguage
      );
      this.translatedContent = JSON.stringify(translatedContent);
    },
    async initArticle(
      articleDefault?: ArticleDto,
      history?: boolean,
      draft?: boolean,
      preserveLocks = true
    ) {
      try {
        this.stopWatchers();
        this.preserveLocks = preserveLocks;

        if (this.currentArticle.id != articleDefault?.id) {
          this.modifExist = modificationType.None;
        }

        if (
          !this.preserveLocks ||
          this.currentArticle.id != articleDefault?.id
        ) {
          await this.deleteLock();
          if (notifyClose) {
            notifyClose();
            notifyClose = null;
          }
        }

        this.currentArticle = createArticleForm(articleDefault, false);
        this.initContents();
        this.logAlert = false;
        this.isHistory = history ?? false;
        this.isDraft = draft ?? false;

        if (this.versionBack?.xmin) {
          this.versionBack.xmin = 0;
        }

        if (
          !this.currentArticle.contents.some((c) => c.language === this.lang)
        ) {
          this.lang = this.currentArticle.idMainLanguage;
        }

        await this.getAndSetVersionBack();

        if (!this.isHistory) {
          this.initLocks();
          this.initNotification();
          this.startWatchers();

          if (
            articleDefault &&
            articleDefault.modificationType !== modificationType.None
          ) {
            this.modifExist = articleDefault.modificationType;
          }
        }
        this.loading = false;
        this.saving = false;
      } catch (err) {
        this.loading = false;
        this.saving = false;
        Dialog.create({
          title: i18n.t("article.dialogs.titles.error"),
          message: i18n.t("article.dialogs.messages.error"),
        }).onOk(() => {
          window.location.reload();
        });
      }
    },
    exportArticle() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { sectionPrimary, sectionSecondaries, ...art } =
        this.currentArticle;
      return {
        ...art,
        sections: this.sectionActives,
        modificationType: this.modifExist,
      };
    },
    async refreshArticle() {
      try {
        clearInterval(lockTimer);
        this.loading = true;
        const art = await articleService.getSingle(this.currentArticle.id);
        await this.initArticle(art, false, false, this.preserveLocks);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.error(e);
        Notify.create(i18n.t("article.errors.onRefresh"));
      }
    },
    async updateArticle() {
      try {
        this.saving = true;
        const art = await articleService.update(this.exportArticle());
        await this.initArticle(art, false, false, this.preserveLocks);
        if (bus) bus.emit("refresh");
        this.saving = false;
      } catch (e) {
        this.saving = false;
        console.error(e);
        const article = this.exportArticle();
        if (article !== undefined) {
          const { putDraft } = useDraftStore();
          article.lastUpdate = new Date().toISOString();
          putDraft(article);
          Notify.create(i18n.t("article.errors.onSave"));
        }
      }
    },
    initLocks(refreshNow = true) {
      clearInterval(lockTimer);
      if (this.currentArticle.id > 0) {
        lockTimer = setInterval(this.refreshLocks, 60_000);
        if (refreshNow) this.refreshLocks();
      } else {
        this.locks.length = 0;
      }
    },
    async refreshLocks() {
      let locks: ArticleLockDto[];
      try {
        locks = await articleService.getLocks(this.currentArticle.id);
      } catch (e) {
        console.error(e);
        return;
      }
      this.locks.length = 0;
      this.locks.push(...locks);

      await this.getAndSetVersionBack();

      this.oldNumberOfLocks = this.locks.filter(
        (lock) => lock.userId != authService.currentUser?.id
      ).length;

      const currentUserLock =
        this.locks.filter((l) => l.userId === authService.getUserId()).length >
        0;

      if (
        this.lockType != modificationType.None &&
        this.modifExist === modificationType.None &&
        !currentUserLock &&
        notifyClose === null
      ) {
        notifyClose = Notify.create({
          type: "negative",
          position: "center",
          message: i18n.t("article.dialogs.messages.inactivityUnlock"),
          timeout: 0,
          actions: [
            {
              icon: "close",
              color: "white",
              label: i18n.t("article.labels.btnClose"),
              "aria-label": i18n.t("article.labels.btnClose"),
            },
          ],
        });
      }

      if (
        this.modifExist !== modificationType.None &&
        currentUserLock &&
        notifyClose
      ) {
        notifyClose();
        notifyClose = null;
      }
    },
    async insertLock() {
      if (this.modifExist > 0 && this.currentArticle.id > 0) {
        this.lockType = this.lockType | this.modifExist;

        const success = await articleService
          .insertLock(this.currentArticle.id, this.modifExist)
          .catch(() => false);

        // si on échoue a prendre le verrou que l'on a pas encore
        if (
          !success &&
          !this.locks.some(
            (l) =>
              l.userId === authService.getUserId() &&
              l.modificationType === this.lockType
          )
        )
          notifyTakeLockError();
      } else clearInterval(lockInterval);
    },
    async deleteLock() {
      if (this.lockType === 0) return;

      this.lockType = 0;
      clearInterval(lockInterval);
      if (this.currentArticle.id === 0 || this.isHistory) return;

      return await articleService.deleteLock(this.currentArticle.id);
    },
    syncDeleteLock() {
      if (this.currentArticle.id === 0 || this.isHistory) return;

      return articleService.syncDeleteLock(this.currentArticle.id);
    },
    async getAndSetVersionBack() {
      try {
        if (this.currentArticle.id != 0) {
          this.versionBack = await articleService.getVersion(
            this.currentArticle.id
          );
        }
      } catch (e) {
        console.error(e);
        Notify.create(i18n.t("article.errors.getVersion"));
        return;
      }
    },
    startWatchers() {
      clearInterval(lockInterval);
      this.stopWatchers();
      watchers = [
        watch(
          [
            () => this.currentArticle.contributions,
            () => this.currentArticle.associatedArticles,
            () => this.currentArticle.settings,
          ],
          () => {
            this.modifExist |= modificationType.Base;
          },
          { deep: true }
        ),
        watch(
          () => this.currentArticle.sectionPrimary,
          (newValue) => {
            this.currentArticle.sectionSecondaries =
              this.currentArticle.sectionSecondaries.filter(
                (sec) => sec !== newValue
              );
          }
        ),
        watch(
          () => ({ ...this.currentArticle }),
          (v, ov) => {
            const vBase = { ...v } as Partial<ArticleForm>;

            const mainContent = vBase.contents?.find(
              (c) => c.language === vBase.idMainLanguage
            );

            const translatedContent = vBase.contents?.find(
              (c) => c.language !== vBase.idMainLanguage
            );

            if (
              mainContent &&
              JSON.stringify(mainContent) !== this.mainContent &&
              !this.languagesLocked[mainContent.language]
            ) {
              this.mainContent = JSON.stringify(mainContent);
              this.modifExist |= getModificationTypeFromLanguage(
                mainContent.language
              );
            }

            if (
              translatedContent &&
              JSON.stringify(translatedContent) !== this.translatedContent &&
              !this.languagesLocked[translatedContent.language]
            ) {
              this.translatedContent = JSON.stringify(translatedContent);
              this.modifExist |= getModificationTypeFromLanguage(
                translatedContent.language
              );
            }

            delete vBase.contents;
            delete vBase.modificationType;

            const ovBase = { ...ov } as Partial<ArticleForm>;
            delete ovBase.contents;
            delete ovBase.modificationType;

            // On s'assure que l'article a été modifié avant de set le modifExist
            if (JSON.stringify(ovBase) !== JSON.stringify(vBase))
              this.modifExist |= modificationType.Base;
          },
          { deep: true }
        ),
        watch(
          () => this.modifExist,
          () => {
            if (
              this.currentArticle.id === 0 ||
              (this.lockType | this.modifExist) === modificationType.None
            ) {
              clearInterval(lockInterval);
              return;
            }

            this.insertLock();
            clearInterval(lockInterval);
            lockInterval = setInterval(this.insertLock, 180_000);
            clearInterval(saveDraftInterval);
            saveDraftInterval = setInterval(() => {
              if (
                this.modifExist === modificationType.None ||
                this.currentArticle.id === 0
              )
                return;

              this.saveCurrentArticleInDraft();
            }, 5_000);
          }
        ),
      ];
      this.initLocks(false);
    },
    stopWatchers() {
      clearInterval(lockInterval);
      watchers.forEach((w) => w());
    },
    saveCurrentArticleInDraft() {
      if (this.isHistory) return;

      const draftStore = useDraftStore();
      const art = this.exportArticle();
      art.lastUpdate = new Date().toISOString();
      draftStore.putDraft(art);
    },
    addSetting(idSetting: number, idContent: number) {
      const { articleSettings } = useArticleSettingStore();
      const content = this.currentArticle.contents.find(
        (c) => c.id == idContent
      );
      if (!content) return;

      const setting = articleSettings.find((e) => e.id == idSetting);
      if (!setting) return;

      if (!content.settings.some((s) => s.id == idSetting))
        content.settings.push(setting);
    },
    removeSetting(idSetting: number, idContent: number) {
      const content = this.currentArticle.contents.find(
        (c) => c.id == idContent
      );
      if (!content) return;

      const index = content.settings.findIndex((e) => e.id == idSetting);
      if (index >= 0) content.settings.splice(index, 1);
    },
    initNotification() {
      if (this.currentArticle.id === 0) return;

      signalRService.subscribeToMessageType(
        `article:${this.currentArticle.id}`,
        "_ALL_",
        this.handleNotification
      );

      signalRService.subscribeToMessageType(
        "_GENERAL_",
        ElcanoNotificationType.PreviewEnd,
        this.handlePreview
      );

      guardRemover = this.$router.beforeResolve((to, from) => {
        if (to.path === from.path) return;

        if (guardRemover != null) {
          guardRemover();
          guardRemover = null;
        }

        signalRService.unsubscribeGroup(`article:${this.currentArticle.id}`);
        signalRService.unsubscribeToMessageType(
          "_GENERAL_",
          ElcanoNotificationType.PreviewEnd,
          this.handlePreview
        );
      });
    },
    async handleNotification<T>(message: {
      type: ElcanoNotificationType;
      data: T;
    }) {
      if (message.type === ElcanoNotificationType.ArticleStatusUpdate) {
        console.log("Refresh article", message.data);
        const id = (message.data as string).split(":")[0];
        const lang = (message.data as string).split(":")[1];

        if (id != this.currentArticle.id.toString()) return;
        if (this.modifExist !== modificationType.None) {
          await this.refreshLocks();
          return;
        }

        statusUpdateLanguage.push(lang);

        clearTimeout(statusUpdateTimeout);
        statusUpdateTimeout = setTimeout(async () => {
          await this.refreshArticle();

          const state = this.currentArticle.contents
            .filter((c) => statusUpdateLanguage.indexOf(c.language) >= 0)
            .map((c) => c.state);

          statusUpdateLanguage.length = 0;
          if (state.length) {
            this.logAlert = state.some(
              (s) =>
                s === WorkflowState.ImportationEnErreur ||
                s === WorkflowState.InvalidationEnErreur
            );
          }
        }, 500);
      }

      if (message.type === ElcanoNotificationType.ArticleLock) {
        console.log("Refresh Lock");
        this.initLocks();
      }

      if (message.type === ElcanoNotificationType.ArticleUnlock) {
        clearInterval(lockInterval);

        if (this.modifExist === modificationType.None) return;

        this.saveCurrentArticleInDraft();

        Dialog.create({
          title: i18n.t("article.dialogs.titles.unlock"),
          message: i18n.t("article.dialogs.messages.unlock", {
            userName: message.data,
          }),
          persistent: true,
        });
      }
    },
    handlePreview(message: {
      type: ElcanoNotificationType;
      data: PreviewResult;
    }) {
      if (message.type === ElcanoNotificationType.PreviewEnd) {
        if (
          this.currentArticle.contents.some(
            (c) => c.id == message.data.idArticle
          )
        ) {
          if (message.data.success) this.logAlert = false;
          else this.logAlert = true;
        }
      }
    },
  },
});
