<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <q-item :class="getLineClass()">
    <q-item-section>
      <div
        class="fit row wrap justify-start items-center content-start q-gutter-x-sm"
      >
        <div style="width: 20px" class="gt-md">
          {{ $t(`flags.${getContent().language}`) }}
        </div>
        <div class="col-grow">
          <router-link
            :to="{
              name: RoutesName.EditArticle,
              params: { id: article.id },
            }"
            target="_blank"
            class="title"
          >
            <div class="row no-wrap q-gutter-md">
              <div>
                <q-img
                  v-if="props.showPublication"
                  :alt="props.article.publication"
                  style="width: 25px"
                  :src="`/img/${props.article.publication}.png`"
                  class="desktop-only"
                />
              </div>
              <div class="ellipsis">{{ getTitle() }}</div>
            </div>
          </router-link>
        </div>
        <div
          v-if="showTranslation"
          class="ellipsis gt-xs q-pr-sm"
          style="font-size: 0.8em; text-align: center"
          v-html="
            $t('article.fields.countWordsReverse', [
              getMainContent(props.article).wordCount,
            ])
          "
        ></div>
        <div v-if="setFreelanceAmount" class="ellipsis gt-xs">
          <StateItem v-bind="{ state: getContent().state }" class="cell-fill" />
        </div>
        <div class="ellipsis gt-xs">
          {{ getDate() }}
        </div>

        <div class="ellipsis gt-xs">
          {{ getContent().format }}
        </div>
        <div
          v-if="showColumnContributor"
          class="gt-md contributions-list ellipsis"
        >
          <q-list dense>
            <q-item
              v-for="(contribution, idx) in filteredContributions"
              :key="'contribution' + idx"
              class="contribution-item"
            >
              <q-item-section>
                <span class="row">
                  <span>{{ $t(`contributionType.${contribution.type}`) }}</span>
                  &nbsp;&nbsp;
                  <b>{{ contribution.user }}</b>
                </span>
              </q-item-section>
            </q-item>
          </q-list>
        </div>

        <div v-if="showSigns" class="ellipsis gt-xs">
          {{ $t("article.fields.countChars", [getContent().charCount]) }}
        </div>
      </div>
    </q-item-section>
    <q-item-section side>
      <div class="q-gutter-x-sm">
        <q-btn
          v-if="setFreelanceAmount"
          round
          color="primary"
          size="sm"
          icon="euro"
          @click="editFreelance()"
        >
          <q-tooltip class="bg-primary">{{
            $t("home.actions.setFreelanceAmount")
          }}</q-tooltip>
        </q-btn>
        <q-btn
          round
          color="primary"
          size="sm"
          icon="edit"
          @click="actions.openNewTabEditArticle(article.id)"
        >
          <q-tooltip class="bg-primary">{{
            $t("home.actions.editArticle")
          }}</q-tooltip>
        </q-btn>
        <q-btn
          round
          outline
          size="sm"
          icon="print"
          @click="handlePreviewPrint(article.id)"
        >
          <q-tooltip>{{ $t("home.actions.printArticlesTooltip") }}</q-tooltip>
        </q-btn>
      </div>
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import { RoutesName } from "@/router/routesName";
import {
  ArticleContentDto,
  ArticleDto,
  ContributionType,
  WorkflowState,
} from "@/types/api";
import { authService } from "@/services/AuthService";
import { useArticleActions } from "@/composables/useArticleActions";
import DialogAddAmount from "@/components/Article/Contribution/DialogAddAmountContribution.vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { date, useQuasar } from "quasar";
import { getContributionTypeOrder } from "@/helpers/contributionState";
import { getMainContent } from "@/helpers/article";
import { computed } from "vue";
import { HomeRowStyle } from "@/types/home";
import StateItem from "@/components/Article/ListArticles/StateItem.vue";

const $q = useQuasar();
const { usersLight } = useUserStore();
const { actions } = useArticleActions();
const router = useRouter();
const defaultMask = "DD/MM/YYYY";

const emits = defineEmits<{
  (e: "remove-article", value: ArticleDto): void;
}>();

const props = defineProps<{
  article: ArticleDto;
  contributionType?: ContributionType | null;
  state?: WorkflowState | null;
  setFreelanceAmount?: boolean | null;
  showContributor?: ContributionType[] | null;
  showPublication?: boolean | null;
  showTranslation?: boolean | null;
  showSigns?: boolean | null;
  getClasses?: ((article: ArticleDto) => HomeRowStyle) | null;
}>();

const showColumnContributor = computed(() => {
  return props.showContributor != undefined && props.showContributor.length > 0;
});

const filteredContributions = computed(() => {
  if (props.showContributor === undefined) return [];
  const contributions = props.article.contributions.filter(
    (c) => props.showContributor?.includes(c.type)
  );
  return contributions
    .sort(
      (a, b) =>
        getContributionTypeOrder(a.type) - getContributionTypeOrder(b.type)
    )
    .map((c) => {
      return {
        type: c.type,
        user: usersLight.find((u) => u.id === c.idContributor.toString())?.name,
      };
    });
});

/**
 * Construit les classes CSS a appliquer à la ligne de l'article
 */
function getLineClass() {
  let classes = "line-article ";

  if (!props.getClasses) return classes;

  classes += getCssFromHomeRowStyle(props.getClasses(props.article));
  return classes;
}

/**
 * Traduit l'enum en classe CSS
 * @param style HomeRowStyle
 */
function getCssFromHomeRowStyle(style: HomeRowStyle) {
  if (style === HomeRowStyle.Error) return "line-article-error";

  if (style === HomeRowStyle.Warning) return "line-article-warning";

  return "";
}

/**
 * Formatte la date de publication
 */
function getDate() {
  if (!props.article.publishedOn) return undefined;
  return date.formatDate(props.article.publishedOn, defaultMask);
}
/**
 * Récupère le titre de l'article
 */
function getTitle() {
  const content = getContent();
  return content.title;
}
/**
 * Récupère le pigiste
 */
function getFreelance() {
  return props.article.contributions.find(
    (c) => c.type == ContributionType.Freelancer
  );
}
/** Edite le montant de la pige */
function editFreelance() {
  const freelance = getFreelance();

  if (freelance === undefined) return undefined;

  $q.dialog({
    component: DialogAddAmount,
    componentProps: {
      contribution: freelance,
      articleId: props.article.id,
    },
  }).onOk(() => {
    emits("remove-article", props.article);
  });
}
/**
 * Retourne le contenu pour l'affichage.
 */
function getContent() {
  let content: ArticleContentDto | undefined;
  const article = props.article;

  if (props.contributionType != undefined) {
    const contribution = article.contributions.find(
      (c) =>
        c.type == props.contributionType &&
        c.idContributor == authService.getUserId()
    );
    const lng = contribution?.idLanguage ?? article.idMainLanguage;
    content = article.contents.find((c) => c.language === lng);
  } else if (props.showTranslation != null) {
    content = article.contents.find(
      (c) =>
        (c.language === article.idMainLanguage && !props.showTranslation) ||
        (c.language !== article.idMainLanguage && props.showTranslation)
    );
  } else if (props.state) {
    content = article.contents.find((c) => c.state === props.state);
  }
  return content ?? props.article.contents[0];
}
/**
 *
 */
function handlePreviewPrint(articleId: number) {
  const routeData = router.resolve({
    name: RoutesName.Preview,
    params: { articleIds: articleId },
  });
  window.open(routeData.href, "_blank");
}
</script>

<style lang="scss" scoped>
.line-article {
  &:hover {
    background-color: rgba(var(--q-primary-rgb), 0.5);
    color: white;
  }
}

.line-article-error {
  background-color: $red-5;

  &:hover {
    background-color: $red-8;
  }

  color: white;
}

.line-article-warning {
  background-color: $orange-5;

  &:hover {
    background-color: $orange-8;
  }
}

.ellipsis {
  font-size: 0.8em;
}
.contributions-list {
  width: 200px;
  font-size: 0.8em;
}
.title {
  font-family: "CooperHewitt", sans-serif;
  font-size: 1rem;
  color: inherit;
  text-decoration: none;
  margin-bottom: 10px;
  font-weight: bold;

  &a {
    &,
    &:visited {
      color: inherit;
    }
  }
  &:hover {
    text-decoration: underline;
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
